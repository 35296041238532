import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";

import Header from "../Common/Header";
import Slider from "../Home/Slider";

import Icon1 from "../../../img/Group 185.svg";
import Icon2 from "../../../img/Group 186.svg";
import Icon3 from "../../../img/Group 187.svg";
import User1 from "../../../img/Rectangle218@3x.png";
import User2 from "../../../img/Image7@3x.png";
import Tiles from "../../../img/Image 2@3x.png";
import GraniteText from "../../../img/granite_text.png";
import servicesbg1 from "../../../img/servicesbg_1.png";
import servicesbg2 from "../../../img/servicesbg_2.png";
import servicesbg3 from "../../../img/servicesbg_3.png";
import hww1 from "../../../img/hww_1.svg";
import hww2 from "../../../img/hww_2.svg";
import hww3 from "../../../img/hww_3.svg";
import openQuote from "../../../img/quote-open.svg";
import closeQuote from "../../../img/quote-close.svg";

import granite from "../../../img/granite_cat.png";
import quartz from "../../../img/quartz_cat.png";
import engg_stone from "../../../img/engg_stone_cat.png";
import marbles from "../../../img/marbles_cat.png";
import more_cat from "../../../img/more_cat.png";

import "./home.css";
import "swiper/swiper.scss";
import SwiperCore, { Autoplay } from "swiper";

import toast, { Toaster } from "react-hot-toast";

import Select from "react-select";

import { TabContent, TabPane, Nav, NavItem, NavLink, Button } from "reactstrap";

import classNames from "classnames";
import request, { NodeURL } from "../../../api/api";

function Home(props) {
  SwiperCore.use([Autoplay]);

  const colourStyles = {
    control: (styles, { isFocused, isSelected }) => ({
      ...styles,
      backgroundColor: "white",
      cursor: "pointer",
      borderColor: isSelected ? "#968c8c" : "#cccccc",
      boxShadow: isSelected ? "#000000" : "",
      ":hover": {
        borderColor: "#000000",
        boxShadow: "#000000",
      },

      ":focus": {
        borderColor: "#000000",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        cursor: "pointer",
        backgroundColor: isFocused ? "#000000" : "white",
        color: isFocused ? "white" : "",
        ":hover": {
          backgroundColor: "#000000",
          color: "white",
        },
      };
    },
    placeholder: (styles) => {
      return {
        ...styles,
        fontSize: "14px",
      };
    },
  };

  const [activeTab, setActiveTab] = useState("1");
  const [maincategoryList, setmaincategoryList] = useState([]);
  const [subcategoryList, setsubcategoryList] = useState([]);
  const [productList, setproductList] = useState([]);

  const [mainCategoryListAll, setMainCategoryListAll] = useState([]);
  const [mainCategoryListselect, setMainCategoryListselect] = useState([]);

  const [coloursListselect, setcoloursListselect] = useState([]);
  const [colours, setcolours] = useState("");
  const [coloursValue, setcoloursValue] = useState("");

  const [mainCategory, setmainCategory] = useState("");
  const [mainCategoryValue, setmainCategoryValue] = useState("");
  const [subCategoryListAll, setsubCategoryListAll] = useState([]);
  const [subCategoryselect, setsubCategoryselect] = useState([]);
  const [subCategory, setsubCategory] = useState("");
  const [subCategoryValue, setsubCategoryValue] = useState("");

  const [testimonials, settestimonials] = useState("");

  const onSelectCategory = (value) => {
    if (value) {
      setmainCategory(value.value);
      setmainCategoryValue(Array.of(value));
      setsubCategory("");
      setsubCategoryValue("");

      subCategoryListFunc(value.value);
    } else {
      setmainCategory("");
      setmainCategoryValue("");
      setsubCategory("");
      setsubCategoryValue("");
      setsubCategoryselect([]);
    }
  };
  const onSelectSubCategory = (valueType) => {
    if (valueType) {
      const value = valueType.value;
      const array = Array.of(valueType);

      setsubCategory(value);
      setsubCategoryValue(array);
    } else {
      setsubCategory("");
      setsubCategoryValue("");
    }
  };
  const onSelectColours = (valueType) => {
    if (valueType) {
      const value = valueType.value;
      const array = Array.of(valueType);

      setcolours(value);
      setcoloursValue(array);
    } else {
      setcolours("");
      setcoloursValue("");
    }
  };

  const getProductsAPI = () => {
    request({
      url: "/site/list/all/products",
      method: "POST",
    }).then((res) => {
      if (res && +res.status === 1) {
        setproductList(res.response.result);
      } else if (res && +res.status === 0) {
        setproductList(res.response.result);
      }
    });
  };

  const categoryList = () => {
    request({
      url: "/site/product/maincategory/listall",
      method: "POST",
    }).then((res) => {
      if (res.status === 1) {
        setMainCategoryListAll(res.response);

        let mainCategoryListselect = res.response.map((item) => ({
          value: item._id,
          label: item.name,
        }));

        setMainCategoryListselect(mainCategoryListselect);
      } else if (res.status === 0) {
        // toast.error(res.response);
      }
    });
  };

  const getPagesAPI = () => {
    request({
      url: "/site/testimonials/get",
      method: "POST",
    }).then((res) => {
      if (res && +res.status === 1) {
        const getTestimonials = res.response.result;

        settestimonials(getTestimonials);
      } else if (res && +res.status === 0) {
      }
    });
  };

  const subCategoryListFunc = (value) => {
    request({
      url: "/site/product/subcategory/listall",
      method: "POST",
      data: {
        mainCategoryId: value,
      },
    }).then((res) => {
      if (res.status === 1) {
        let subCategoryselect = res.response.map((item) => ({
          value: item._id,
          label: item.name,
        }));

        setsubCategoryselect(subCategoryselect);
      } else if (res.status === 0) {
        // toast.error(res.response);
      }
    });
  };

  const coloursList = () => {
    request({
      url: "/site/product/colours/listall",
      method: "POST",
    }).then((res) => {
      if (res.status === 1) {
        let coloursListselect = res.response.map((item) => ({
          value: item._id,
          label: item.name,
        }));

        setcoloursListselect(coloursListselect);
      } else if (res.status === 0) {
        toast.error(res.response);
      }
    });
  };

  const onToast = () => {
    toast.success("Logged In !");
    localStorage.removeItem("KGUT");
  };

  const goTo = (item, type, category, categoryDescription) => {
    props.history.push({
      pathname: "/prodlist",
      state: {
        _id: item._id,
        type: type,
        category: category,
        categoryDescription: categoryDescription,
      },
    });
  };
  const goToSelect = (mainCategory, subCategory, colours) => {
    if (mainCategory === "" && subCategory === "" && colours === "")
      return props.history.push({
        pathname: "/prodlist",
        state: {
          filter: false,
        },
      });

    return props.history.push({
      pathname: "/prodlist",
      state: {
        filter: true,
        mainCategory: mainCategory,
        subCategory: subCategory,
        colours: colours,
      },
    });
  };
  const page = (path) => {
    props.history.push({
      pathname: `/${path}`,
    });
  };

  const isEven = (n) => {
    return n % 2 == 0;
  };

  const isOdd = (n) => {
    return Math.abs(n % 2) == 1;
  };

  const getMainCategoryAPI = () => {
    request({
      url: "/site/product/list/maincategory",
      method: "POST",
      // data: this.state.tableOptions,
    }).then((res) => {
      if (res && +res.status === 1) {
        setmaincategoryList(res.response.result);

        getSubcategoryAPI(res.response.result);
      } else if (res && +res.status === 0) {
        setmaincategoryList(res.response.result);
      }
    });
  };
  const getSubcategoryAPI = (mainCategory) => {
    request({
      url: "/site/product/list/subcategory",
      method: "POST",
      // data: this.state.tableOptions,
    }).then((res) => {
      if (res && +res.status === 1) {
        setsubcategoryList(res.response.result);
      } else if (res && +res.status === 0) {
        setsubcategoryList(res.response.result);
      }
    });
  };

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  useEffect(() => {
    const loggedIn = localStorage.getItem("KGUT");
    if (loggedIn !== null) onToast();

    getMainCategoryAPI();
    getProductsAPI();

    categoryList();
    coloursList();

    getPagesAPI();
  }, []);

  return (
    <>
      <Header />
      <Toaster
        position="bottom-center"
        reverseOrder={false}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options

          // Default options for specific types
          duration: 3000,
          style: {
            background: "#363636",
            color: "#fff",
            width: "100%",
          },
        }}
      />
      <div className="page-wrapper HomePage">
        <section className="hero-section">
          <Slider />
          <div className="hero-content">
            <img src={GraniteText} alt="Granite Countertops" />
            <h6 className="ft-regular text-white mt-3">
              cutting, polishing, fitting &amp; installation
            </h6>
          </div>
          <div className="head-dropdown pb-4 pt-4">
            <h5 id="productSearchHeader">Find your Stone</h5>
            <div className="row">
              <div className="col-md-12">
                <div>
                  <p>Color</p>
                  <div className="dropdown">
                    <Select
                      name="subCategory"
                      isClearable={true}
                      isSearchable={true}
                      options={coloursListselect}
                      cacheOptions
                      placeholder="Select Color"
                      styles={colourStyles}
                      value={coloursValue}
                      onChange={(e) => onSelectColours(e)}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div>
                  <p>Material</p>
                  <div className="dropdown">
                    <Select
                      name="mainCategory"
                      isClearable={true}
                      isSearchable={true}
                      options={mainCategoryListselect}
                      cacheOptions
                      placeholder="Select Material"
                      styles={colourStyles}
                      value={mainCategoryValue}
                      // defaultMenuIsOpen
                      onChange={(e) => onSelectCategory(e)}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div>
                  <p>Type</p>
                  <div className="dropdown">
                    <Select
                      name="subCategory"
                      isClearable={true}
                      isSearchable={true}
                      options={subCategoryselect}
                      cacheOptions
                      placeholder="Select Type"
                      styles={colourStyles}
                      value={subCategoryValue}
                      onChange={(e) => onSelectSubCategory(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <Button
              className="text-center mx-auto block mb-2"
              style={{ display: "block" }}
              onClick={() => goToSelect(mainCategory, subCategory, colours)}
            >
              View Selection
            </Button>
          </div>
        </section>
        <section className="categories-section mt-5 mb-5" id="cat-sec">
          <div className="container">
            <div className="d-flex justify-content-start align-items-center my-5">
              <p id="sec-head">
                1<div className="cat-line"></div>
                <span style={{ marginLeft: "15px" }}>Categories</span>
              </p>
            </div>
            <div className="row">
              {maincategoryList.length > 0
                ? maincategoryList.map((item, i) =>
                    i <= 4 ? (
                      <div className="col-md-6 col-lg-4" key={i}>
                        <div className="cat-item">
                          <img
                            src={`${NodeURL + "/"}${item.pictures}`}
                            alt="Granites"
                          />
                          <div className="cat-item-content">
                            <h5>{item.name}</h5>
                            <p>
                              {
                                subcategoryList.filter(
                                  (sub) => item._id === sub.mainCategory
                                ).length
                              }{" "}
                              Categories &bull;{" "}
                              {
                                productList.filter(
                                  (prod) => item._id === prod.mainCategory
                                ).length
                              }{" "}
                              Products
                            </p>
                          </div>
                          <div className="cat-item-info">
                            <h4 className="c-info-head">{item.name}</h4>
                            <ul className="c-info-list">
                              {subcategoryList.length > 0
                                ? subcategoryList.map((sub, j) =>
                                    item._id === sub.mainCategory ? (
                                      j <= 4 ? (
                                        <li key={j}>
                                          <a
                                            onClick={() =>
                                              goTo(
                                                sub,
                                                "sub",
                                                sub.mainCategoryName,
                                                item.description
                                              )
                                            }
                                          >
                                            {sub.name}
                                            <i className="fa fa-angle-right"></i>
                                          </a>
                                        </li>
                                      ) : null
                                    ) : null
                                  )
                                : null}
                            </ul>
                            <div className="cat-divider"></div>
                            <Button
                              id="catExplore"
                              onClick={() =>
                                goTo(item, "main", item.name, item.description)
                              }
                            >
                              Explore All
                            </Button>
                          </div>
                        </div>
                      </div>
                    ) : null
                  )
                : null}

              <div
                className="col-md-6 col-lg-4"
                onClick={() => props.history.push("/allproducts")}
              >
                <div className="cat-item">
                  <img src={more_cat} alt="Granites" />
                  <div className="cat-item-content">
                    <h5>More...</h5>
                  </div>
                  <div className="cat-item-info">
                    <h4
                      className="c-info-head more-info"
                      style={{ cursor: "pointer" }}
                    >
                      View All
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="highlights">
          <div className="container">
            <div className="row text-center">
              <div className="col-md-4">
                <div className="info-content-box mb-3 d-flex justify-content-around align-items-end">
                  <h6>tough & durable</h6>
                  <img className="img-fluid mb-3" src={Icon1} />
                </div>
              </div>
              <div className="col-md-4">
                <div className="info-content-box mb-3 d-flex justify-content-around align-items-end">
                  <h6 className="mt-2">Scratch Resistant</h6>
                  <img className="img-fluid mb-3" src={Icon2} />
                </div>
              </div>
              <div className="col-md-4">
                <div className="info-content-box mb-3 d-flex justify-content-around align-items-end">
                  <h6>Easy Maintainance</h6>
                  <img className="img-fluid mb-3" src={Icon3} />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="info-content mt-4">
                  <p>
                    We provide stunning, smooth finish that is stain and scratch
                    resistant with an appearance that does not fade. Popular in
                    spaces such as kitchens, bathrooms and office counters.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="service-section">
          <img
            src={
              activeTab === "1"
                ? servicesbg1
                : activeTab === "2"
                ? servicesbg2
                : activeTab === "3"
                ? servicesbg3
                : null
            }
            alt="Service Section"
            id="service-img"
          />
          <div className="serImg-overlay"></div>
          <div className="container">
            <div className="d-flex justify-content-start align-items-center my-5">
              <p id="sec-head">
                2<div className="cat-line bg-white"></div>
                <span style={{ marginLeft: "15px" }}>Services</span>
              </p>
            </div>
            <div className="row">
              <div className="col-md-8">
                <div className="upgrade-content mb-5">
                  <h3>Need an Upgrade?</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor invidunt ut labore et dolore magna
                    aliquyam erat, sed diam voluptua. At vero eos et
                  </p>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-md-12">
                <Nav tabs className="align-items-center">
                  <div className="col-md-3 p-0 pr-md-0 text-center mb-3">
                    <NavItem className="light-bdr">
                      <NavLink
                        className={classNames({
                          active: activeTab === "1",
                        })}
                        onClick={() => {
                          toggle("1");
                        }}
                      >
                        Redesign
                      </NavLink>
                    </NavItem>
                  </div>
                  <div className="col-md-3 p-0 pr-md-0 text-center mb-3">
                    <NavItem className="light-bdr">
                      <NavLink
                        className={classNames({
                          active: activeTab === "2",
                        })}
                        onClick={() => {
                          toggle("2");
                        }}
                      >
                        Kitchen
                      </NavLink>
                    </NavItem>
                  </div>
                  <div className="col-md-3 p-0 pr-md-0 text-center mb-3">
                    <NavItem className="light-bdr">
                      <NavLink
                        className={classNames({
                          active: activeTab === "3",
                        })}
                        onClick={() => {
                          toggle("3");
                        }}
                      >
                        Bathroom
                      </NavLink>
                    </NavItem>
                  </div>
                  <div className="col-md-3 pr-md-0 mb-3">
                    <div className="content-detail-inner">
                      <div className="text-center inner-link">
                        <a
                          className="underline"
                          onClick={() => page("services")}
                        >
                          More Services{" "}
                          <i
                            className="fa fa-angle-right"
                            aria-hidden="true"
                          ></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </Nav>
              </div>
            </div>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <div className="ss-wrap">
                  <div className="head-content">
                    <h3>Do you want redesign your space?</h3>
                  </div>
                  <div className="body-content">
                    <p>
                      Get unique designs, that are custom-made, heat-resistant
                      and easy to maintain all with a stone that you’ll never
                      have to replace.
                    </p>
                  </div>
                  <Button
                    id="srqQuote"
                    onClick={() => props.history.push("/quotes")}
                  >
                    Request a Quote
                  </Button>
                </div>
              </TabPane>
              <TabPane tabId="2">
                <div className="ss-wrap">
                  <div className="head-content">
                    <h3>High-quality granite kitchen countertops?</h3>
                  </div>
                  <div className="body-content">
                    <p>
                      No matter what the size of your kitchen is, it will always
                      remain the hub of your house. It is the important part of
                      your home that needs equal attention and maintenance as
                      you give to your bedrooms.
                    </p>
                  </div>
                  <Button
                    id="srqQuote"
                    onClick={() => props.history.push("/quotes")}
                  >
                    Request a Quote
                  </Button>
                </div>
              </TabPane>
              <TabPane tabId="3">
                <div className="ss-wrap">
                  <div className="head-content">
                    <h3>high-quality granite kitchen countertops</h3>
                  </div>
                  <div className="body-content">
                    <p>
                      No matter what the size of your kitchen is, it will always
                      remain the hub of your house. It is the important part of
                      your home that needs equal attention and maintenance as
                      you give to your bedrooms.
                    </p>
                  </div>
                  <Button
                    id="srqQuote"
                    onClick={() => props.history.push("/quotes")}
                  >
                    Request a Quote
                  </Button>
                </div>
              </TabPane>
            </TabContent>
          </div>
        </section>
        <section id="work-section">
          <div className="container">
            <div className="d-flex justify-content-start align-items-center my-5">
              <p id="sec-head">
                3<div className="cat-line"></div>
                <span style={{ marginLeft: "15px" }}>How We Work</span>
              </p>
            </div>
            <div className="row mt-3">
              <div className="col-md-4 col-sm-12 col-lg-4">
                <img src={hww1} alt="How we work?" />
                <p className="ws-text">
                  We Consult with you to find the perfect product for you
                  project
                </p>
              </div>
              <div className="col-md-4 col-sm-12 col-lg-4">
                <img src={hww2} alt="How we work?" />
                <p className="ws-text">
                  We send you a detailed quote, when you accept you pay a
                  deposit
                </p>
              </div>
              <div className="col-md-4 col-sm-12 col-lg-4">
                <img src={hww3} alt="How we work?" />
                <p className="ws-text">
                  We come to your house, Take templates, Manufacture tops and
                  Install
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="aboutus-section">
          <div className="container-fluid p-0 no-gutters">
            <div className="container">
              <div className="row">
                <div className="col-md-4 content-box">
                  <div className="d-flex justify-content-start align-items-center mb-5">
                    <p id="sec-head">
                      4<div className="cat-line"></div>
                      <span style={{ marginLeft: "15px" }}>About Us</span>
                    </p>
                  </div>
                  <p className="content-p pr-md-5">
                    We are your granite counter top provider as well as full
                    service installers, dedicated to helping you modernise your
                    home or office space with customised designs. We strive for
                    the best quality at the lowest prices, with many colours and
                    textures to choose from Our experienced and friendly team is
                    available to deliver personal service, excellent customer
                    care, and fine craftsmanship, ensuring that we transform
                    your space into a luxury that will last a lifetime.
                  </p>
                  <div className="bottom-btn">
                    <div className="content-detail-inner">
                      <div className="text-left inner-link">
                        <a
                          className="underline"
                          onClick={() => page("aboutus")}
                        >
                          Read More
                          <i
                            className="fa fa-angle-right"
                            aria-hidden="true"
                            style={{ paddingLeft: "8px" }}
                          ></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="offset-md-5 no-gutters col-md-7">
              <div className="img-wrapper ml-1 aboutus-img"></div>
            </div>
          </div>
        </section>
        <section className="testimonial-section">
          <div className="container">
            <div className="d-flex justify-content-start align-items-center mb-5">
              <p id="sec-head">
                5<div className="cat-line"></div>
                <span style={{ marginLeft: "15px" }}>Testimonials</span>
              </p>
            </div>
            <div className="head-content">
              <h5>what our</h5>
              <h3>clients say</h3>
            </div>
            <div className="quote-open">
              <img src={openQuote} alt="open-quote" />
            </div>
            <div className="quote-close">
              <img src={closeQuote} alt="open-quote" />
            </div>
            <div className="swiper-container">
              <div className="swiper-wrapper">
                {testimonials.length > 0 ? (
                  <Swiper
                    slidesPerView={3}
                    pagination={{ clickable: true }}
                    loop={true}
                    autoplay={{ delay: 3000 }}
                    // onSlideChange={(e) => console.log(e.realIndex)}
                    // onSwiper={(swiper) => console.log(swiper)}
                    breakpoints={{
                      // when window width is >= 640px
                      240: {
                        slidesPerView: 1,
                        spaceBetween: 10,
                      },
                      // when window width is >= 768px
                      768: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                      },
                      1024: {
                        slidesPerView: 3,
                        spaceBetween: 60,
                      },
                    }}
                  >
                    {testimonials.map((item, i) => (
                      <SwiperSlide key={i}>
                        <div className="swiper-slide">
                          <div className="testi-box shadow">
                            <img
                              className="img-fluid img-wrapper d-block"
                              alt="testimonials"
                              src={
                                item.photo === "" || item.photo === null
                                  ? User1
                                  : NodeURL + "/" + item.photo
                              }
                            />
                            <div className="testi-content ">
                              <div className="tesi-box-para">
                                <p>{item.content}</p>
                                <p className="p-0 text-right ft-semibolditalic">
                                  - {item.author}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                ) : null}
              </div>
            </div>
            <div className="swiper-pagination"></div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Home;
