import React, { useEffect, useState, useRef } from 'react';
import './gallery.css';
import { Row, Col } from 'reactstrap';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useHistory } from 'react-router-dom';

import HeaderOthers from '../Common/HeaderOthers';
import request, { NodeURL } from '../../../api/api';

import emptyGallery from '../../../img/emptyGallery.svg';

function Gallery() {
  const history = useHistory();
  const galleryHeaderRef = useRef();

  useEffect(() => {
    galleryHeaderRef.current?.scrollIntoView(true);

    return () => {
      galleryHeaderRef.current = null;
    };
  }, [galleryHeaderRef]);

  const [isLoading, setIsLoading] = useState(true);
  const [galleryList, setGalleryList] = useState([]);

  useEffect(() => {
    const getGalleries = () => {
      request({
        url: '/user/list/gallery',
        method: 'POST',
      }).then((res) => {
        if (res.status === 1) {
          setGalleryList(res.response.result);
        }

        setIsLoading(false);
      });
    };

    getGalleries();
  }, []);

  const goToGalleryDetails = (id) => {
    history.push({
      pathname: '/gallery/details',
      state: {
        rowid: id,
      },
    });
  };

  return (
    <>
      <HeaderOthers />
      <section className='gallery-section'>
        <div className='gallery-header' ref={galleryHeaderRef}>
          <h3>OUR PROJECTS</h3>
          <h5>WE’RE PROUD OF OUR WORK</h5>
          <p>
            Have a look through our wide variety of projects and homes that have trusted Kingdom
            Granites Kitchens to deliver on style, quality and workmanship.
          </p>
        </div>
        <div className='container'>
          <div className='gallery-area'>
            <Row>
              {isLoading ? (
                [1, 2, 3, 4, 5, 6].map((idx) => {
                  return (
                    <Col className='mb-4' sm='12' lg='4' md='4' key={idx}>
                      <SkeletonTheme color='#efefef' highlightColor='#dedede'>
                        <Skeleton height={300} />
                      </SkeletonTheme>
                    </Col>
                  );
                })
              ) : (
                <>
                  {galleryList.length === 0 ? (
                    <Col sm='12' lg='12' md='12'>
                      <div className='gallery-no-img'>
                        <img src={emptyGallery} alt='Empty Gallery' />
                        <h6>No Images in the Gallery</h6>
                      </div>
                    </Col>
                  ) : (
                    galleryList.map((gallery, idx) => {
                      return (
                        <Col sm='12' lg='4' md='4' key={idx}>
                          <div
                            className='image-item'
                            onClick={() => goToGalleryDetails(gallery._id)}
                          >
                            <img
                              src={`${NodeURL}/${gallery.pictures[0]}`}
                              alt={`gallery-${gallery.title}`}
                            />
                            <h6>{gallery.title}</h6>
                          </div>
                        </Col>
                      );
                    })
                  )}
                </>
              )}
            </Row>
          </div>
        </div>
      </section>
    </>
  );
}

export default Gallery;
