import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import HeaderOthers from '../Common/HeaderOthers';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import PageBack from '../../../img/pageBack.svg';
import request, { NodeURL } from '../../../api/api';
import toast from 'react-hot-toast';

function GalleryDetails({ history, location }) {
  const [isLoading, setIsLoading] = useState(true);
  const [galleryDetails, setGalleryDetails] = useState({
    _id: '',
    title: '',
    description: '',
    pictures: [],
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getGalleryDetails = (galleryId) => {
    request({
      url: '/user/gallery/get',
      method: 'POST',
      data: {
        galleryId,
      },
    }).then((res) => {
      if (res.status === 0) {
        toast.error(res.message);
      }

      if (res.status === 1) {
        setGalleryDetails(res.response);
      }

      setIsLoading(false);
    });
  };

  useEffect(() => {
    const { rowid } = location?.state;

    if (!rowid) {
      return history.goBack();
    } else {
      getGalleryDetails(rowid);
    }
  }, []);

  const [activeShowcaseMain, setActiveShowcaseMain] = useState('');

  useEffect(() => {
    setActiveShowcaseMain(galleryDetails.pictures[0]);
  }, [galleryDetails]);

  const goToQuotes = () => history.push('/quotes');

  return (
    <>
      <HeaderOthers />
      {galleryDetails && (
        <section className='gallery-section'>
          <div className='container'>
            <span id='back' data-cursor onClick={history.goBack}>
              <img src={PageBack} width={16} className='mr-3' alt='Back' />
              Back to gallery
            </span>
            <div className='gallery-area mt-4'>
              {isLoading ? (
                <SkeletonTheme color='#efefef' highlightColor='#dedede'>
                  <Row>
                    <Col sm='12' lg='5' md='5'>
                      <div className='showcase-area'>
                        <Skeleton className='showcase-main' />

                        <div className='showcase-thumb'>
                          {[1, 2, 3].map((idx) => (
                            <Skeleton className='thumb-img' height={100} />
                          ))}
                        </div>
                      </div>
                    </Col>
                    <Col sm='12' lg='7' md='7'>
                      <div className='px-4'>
                        <h4>
                          <Skeleton height={20} width={350} />
                        </h4>
                        <p>
                          <Skeleton height={10} count={5} />
                        </p>
                        <Skeleton className='mt-4' height={30} width={180} />
                      </div>
                    </Col>
                  </Row>
                </SkeletonTheme>
              ) : (
                <Row>
                  <Col sm='12' lg='5' md='5'>
                    <div className='showcase-area'>
                      <img
                        src={`${NodeURL}/${activeShowcaseMain}`}
                        className='showcase-main'
                        alt='Gallery'
                      />
                      {galleryDetails.pictures.length > 1 && (
                        <div className='showcase-thumb'>
                          {galleryDetails.pictures.map((picture) => {
                            return (
                              <img
                                src={`${NodeURL}/${picture}`}
                                alt='Thumbnail'
                                key={picture}
                                style={{ cursor: 'pointer' }}
                                className={`thumb-img ${
                                  picture === activeShowcaseMain ? 'active' : ''
                                }`}
                                onClick={() => setActiveShowcaseMain(picture)}
                              />
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col sm='12' lg='7' md='7'>
                    <div className='px-4'>
                      <h4>{galleryDetails.title}</h4>
                      <p>{galleryDetails.description}</p>
                      <button id='enquireNowBtn' onClick={goToQuotes}>
                        Request a Quote
                      </button>
                    </div>
                  </Col>
                </Row>
              )}
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export default GalleryDetails;
