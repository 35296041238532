import React, { Component } from "react";
import { Link } from "react-router-dom";
import FooterLogo from "../../../img/Logo-1@3x.png";

import "./common.css";

import request from "../../../api/api";

export default class Footer extends Component {
  state = {
    email: "",
    emailSales: "",
    social: [],
    address: {
      line1: "",
      line2: "",
      state: "",
      stateValue: "",
      city: "",
      cityValue: "",
      province: "",
      zip: "",
      postal_code: "",
      formatted_address: "",
      lat: "",
      lng: "",
    },
    phone: {
      number: "",
      code: "",
      dialcountry: "",
    },
    copyright: "",
    workingDays: "",
    workingDaysSaturday: "",
    workingDaysSunday: "",
    workingDaysValue: "",
  };

  componentDidMount() {
    this.getGeneralSettingsAPI();
    this.getSocialSettingsAPI();
  }

  getSocialSettingsAPI = () => {
    request({
      url: "/getSocialSettings",
      method: "POST",
    }).then((res) => {
      if (res && +res.status === 1) {
        const settings = res.settings;
        this.setState({
          social: settings.social,
        });
      }
    });
  };
  getGeneralSettingsAPI = () => {
    request({
      url: "/getGeneralSettings",
      method: "POST",
    }).then((res) => {
      if (res && +res.status === 1) {
        const settings = res.settings;
        this.setState({
          email: settings.email,
          emailSales: settings.emailSales,
          address: settings.address,
          phone: settings.phone,
          copyright: settings.copyright,
          workingDays: settings.opening_hours.mon,
          workingDaysSaturday: settings.opening_hours.sat,
          workingDaysSunday: settings.opening_hours.sun,
        });
      }
    });
  };
  render() {
    const {
      workingDays,
      workingDaysSaturday,
      workingDaysSunday,
      email,
      emailSales,
      phone,
      copyright,
      address,
      social,
    } = this.state;
    return (
      <footer className="text-white">
        <div className="container pt-5">
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-3">
              <div className="ft-first">
                <div className="ft-logo mb-3">
                  <img className="img-fluid" src={FooterLogo} />
                </div>
                <div className="ft-cell d-flex">
                  <i className="fa fa-phone mt-1 mr-3" aria-hidden="true"></i>
                  <p>+27 {phone.number}</p>
                </div>
                <div className="ft-email d-flex">
                  <i
                    className="fa fa-envelope-o mt-1 mr-3"
                    aria-hidden="true"
                  ></i>
                  <p>
                    {email} <br />
                    {emailSales}
                  </p>
                </div>
                <div className="ft-add d-flex">
                  <i
                    className="fa fa-map-marker mt-1 mr-3"
                    aria-hidden="true"
                  ></i>
                  <p>
                    {address.line1},
                    <br />
                    {address.line2}, {address.city}, {address.postal_code}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 d-flex">
              <div className="col-md-3">
                <div className="ft-explore">
                  <div className="explore mb-4">
                    <h6>Explore</h6>
                  </div>
                  <div className="ft-about">
                    <Link to="/aboutus">About</Link>
                  </div>
                  <div className="ft-contact">
                    <Link to="/contactus">Contact</Link>
                  </div>
                  <div className="ft-products">
                    <Link to="/allproducts">Products</Link>
                  </div>
                  <div className="ft-services">
                    <Link to="/services">Services</Link>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div className="ft-legal ">
                  <div className="legal mb-4">
                    <h6>Legal</h6>
                  </div>
                  <div className="ft-tandc">
                    <Link to="/termsandconditions">Terms and Conditions</Link>
                  </div>
                  <div className="ft-pp">
                    <Link to="/privacypolicy">Privacy Policy</Link>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="ft-follow ">
                  <div className="follow mb-4">
                    <h6>Follow</h6>
                  </div>
                  {social.length > 0
                    ? social.map((item, i) => (
                        <div className="ft-fb" key={i}>
                          <a href={item.url} target="_blank" rel="noreferrer">{item.name}</a>
                        </div>
                      ))
                    : null}
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-3">
              <div className="ft-follow ">
                <div className="open-hours mb-4">
                  <h6>Opening Hours</h6>
                </div>
                <div className="ft-day">
                  <p>Mon-Fri: {workingDays}</p>
                </div>
                <div className="ft-day">
                  <p>Saturday: {workingDaysSaturday}</p>
                </div>
                <div className="ft-day">
                  <p>Sunday: {workingDaysSunday}</p>
                </div>
              </div>
            </div>
          </div>
          <hr className="footer-hr" />
          <div className="row">
            <div className="col-md-12 text-center">
              <p className="cpy-ryt">
                COPYRIGHT {new Date().getFullYear()} © KINGDOM GRANITE (PTY) LTD
              </p>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
