import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import Logo from "../../../img/logo.png";
import LogoLight from "../../../img/logo_light.png";
import LogoDark from "../../../img/logo_dark.png";

import "./common.css";

import toast from "react-hot-toast";

import jwt_decode from "jwt-decode";

import ModalUser from "./Modal";

class HeaderOthers extends Component {
  state = {
    pathname: window.location.pathname,
    innerTabs: "",
    activeTab: "1",
    isLawyerAppModal: false,
    otpVerified: false,
    isQuote: false,
    toggleMenu: false,
    fullName: "",
    modalState: "",
    email: "",
    phone: {
      number: "",
      code: "",
      dialcountry: "",
    },
    password: "",
    new_password: "",
    confirmPassword: "",
    userData: "",
    otp1: "",
    otp2: "",
    otp3: "",
    otp4: "",
  };

  componentDidMount() {
    const getData = localStorage.getItem("KGU");

    if (getData) {
      const token = jwt_decode(getData);

      if (token) {
        this.setState({
          userData: token,
        });
      }
    }
  }
  logOut = () => {
    localStorage.removeItem("KGU");
    this.setState({
      userData: "",
    });

    toast.success("Logged out");

    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  userPage = (path) => {
    window.location.pathname = `/user/${path}`;
  };

  onOpenLawyerAppModal = () => {
    this.setState({
      isLawyerAppModal: !this.state.isLawyerAppModal,
      email: "",
      modalState: "",
      otp1: "",
      otp2: "",
      otp3: "",
      otp4: "",
    });
  };

  render() {
    const { pathname, isLawyerAppModal, userData, isQuote, toggleMenu } =
      this.state;
    return (
      <nav className="navbar navbar-expand-lg navbar-light header-page bg-white">
        <div className="navbar-container">
          <div>
            <a href="/" className="navbar-brand logo-size logo-big">
              <img className="img-fluid" src={LogoDark} id="logo-holder" />
            </a>
            <button
              type="button"
              className="navbar-toggler"
              data-toggle="collapse"
              data-target="#navbarCollapse"
              onClick={() => {
                this.setState({
                  toggleMenu: !toggleMenu,
                });
              }}
            >
              <i
                className={`${
                  toggleMenu ? "fa fa-times" : "fa fa-bars"
                } navbar-toggle-icon`}
              ></i>
            </button>
          </div>
          <div
            id="navbarCollapse"
            className="collapse navbar-whitebg navbar-collapse"
          >
            <div className="navbar-nav">
              <div className="nav-item dropdown">
                <a
                  className="nav-link profile-dd dropdown-toggle"
                  href="#"
                  id="navbarDropdownWhite"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Products
                </a>
                <div
                  className="dropdown-menu w-100 dropdown-menu w-100-right"
                  aria-labelledby="navbarDropdownWhite"
                >
                  <Link to="/allproducts" className="dropdown-item profile">
                    Categories
                  </Link>
                  <div className="dropdown-divider"></div>
                  <Link to="/prodlist" className="dropdown-item profile">
                    All Products
                  </Link>
                </div>
              </div>
              <Link
                eventKey="services"
                to="/services"
                className={`nav-item nav-link ${
                  pathname === "/services" ? "active" : ""
                } `}
              >
                Services
              </Link>
              <Link
                eventKey="services"
                to="/gallery"
                className={`nav-item nav-link ${
                  pathname === "/gallery" ? "active" : ""
                } `}
              >
                Gallery
              </Link>
              <Link
                eventKey="aboutus"
                to="/aboutus"
                className={`nav-item nav-link ${
                  pathname === "/aboutus" ? "active" : ""
                } `}
              >
                About us
              </Link>
              <Link
                eventKey="contactus"
                to="/contactus"
                className={`nav-item nav-link ${
                  pathname === "/contactus" ? "active" : ""
                } `}
              >
                Contact us
              </Link>
            </div>
            {/* after logged in start */}

            {userData !== "" ? (
              <>
                <div className="nav-item dropdown">
                  <a
                    className="nav-link profile-dd dropdown-toggle"
                    href="#"
                    id="navbarDropdownAsWhite"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    My Account
                  </a>
                  <div
                    className="dropdown-menu w-100 dropdown-menu w-100-right"
                    aria-labelledby="navbarDropdown"
                  >
                    <Link
                      className="dropdown-item profile"
                      onClick={() => this.userPage("profile")}
                    >
                      Profile
                    </Link>
                    <div className="dropdown-divider"></div>
                    <Link
                      className="dropdown-item notification"
                      onClick={() => this.userPage("notifications")}
                    >
                      Notification
                    </Link>
                    <div className="dropdown-divider"></div>
                    <Link
                      className="dropdown-item myquote"
                      onClick={() => this.userPage("quotes")}
                    >
                      My Quote
                    </Link>
                    <div className="dropdown-divider"></div>
                    <Link
                      className="dropdown-item myorder"
                      onClick={() => this.userPage("orders")}
                    >
                      My Order
                    </Link>
                    <div className="dropdown-divider"></div>
                    <Link
                      className="dropdown-item logout"
                      onClick={this.logOut}
                    >
                      Logout
                    </Link>
                  </div>
                </div>
                <div className="navbar-nav login-btn">
                  <div className="quote">
                    <Link
                      to="/quotes"
                      className="nav-link"
                      id="quoteLinkAsWhite"
                    >
                      Request a Quote
                    </Link>
                  </div>
                </div>
              </>
            ) : (
              <div className="navbar-nav login-btn">
                <div className="quote">
                  <Link to="/quotes" className="nav-link" id="quoteLinkWhite">
                    Request a Quote
                  </Link>
                </div>
                <div className="signin">
                  <a
                    to="/signin"
                    className="nav-link"
                    id="signInLinkWhite"
                    onClick={this.onOpenLawyerAppModal}
                  >
                    Sign In
                  </a>
                </div>
              </div>
            )}
          </div>

          {isLawyerAppModal && (
            <ModalUser
              isLawyerAppModal={isLawyerAppModal}
              onOpenLawyerAppModal={this.onOpenLawyerAppModal}
              isQuote={isQuote}
              {...this.props}
            />
          )}
        </div>
      </nav>
    );
  }
}
export default withRouter(HeaderOthers);
