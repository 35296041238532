import React, { Component } from "react";
import request from "../../../api/api";
import { Link } from "react-router-dom";

import HeaderOthers from "../Common/HeaderOthers";

import "./aboutus.css";

class AboutUs extends Component {
  state = {
    content: "",
  };
  componentDidMount() {
    window.scroll(0, 0);
    this.getPagesAPI();
  }
  getPagesAPI = () => {
    const pathname = this.props.location.pathname.replace(/[^\w\s]/gi, "");
    request({
      url: "/site/pages/get",
      method: "POST",
      data: {
        slug: pathname,
      },
    }).then((res) => {
      if (res && +res.status === 1) {
        const { content } = res.response.result;

        this.setState({
          isLoader: false,
          content,
        });
      } else if (res && +res.status === 0) {
        this.setState({
          isLoader: false,
        });
      }
    });
  };
  render() {
    let texts = this.state.content;
    function createMarkup(text) {
      return { __html: text };
    }
    return (
      <>
        <HeaderOthers />
        <div className="page-wrapper aboutus">
          <section className="hero-section-aboutus">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-3">
                  <div className="homepage">
                  <Link to="/">Home</Link>
                    <Link to="/aboutus">
                      <i className="fa fa-circle" aria-hidden="true"></i>
                      About Us
                    </Link>
                  </div>
                </div>
                <div className="offset-md-1 text-center col-md-4">
                  <div className="content-box">
                    <h2>About us</h2>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="img-wrapper aboutus-head-img"></div>
          <section className="aboutus-content-box">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-6">
                  <div
                    className="para-content"
                    dangerouslySetInnerHTML={createMarkup(texts)}
                  ></div>
                </div>
                <div className="col-md-6 p-0 m-0 no-gutters">
                  <div className="img-wrapper aboutus-content-img"></div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

export default AboutUs;
