import React, { Component } from "react";
import ContactUsImg from "../../../img/Contact us-rafiki (3).svg";
import FacebookIcon from "../../../img/social/Group 2855.svg";
import TwitterIcon from "../../../img/social/Group 2856.svg";
import InstagramIcon from "../../../img/social/Group 2857.svg";
import LinkedinIcon from "../../../img/social/Group 2858.svg";

import HeaderOthers from "../Common/HeaderOthers";

import "./contactus.css";

import { Link } from "react-router-dom";

import toast, { Toaster } from "react-hot-toast";
import request from "../../../api/api";

class ContactUs extends Component {
  state = {
    name: "",
    email: "",
    subject: "",
    message: "",

    address: {
      line1: "",
      line2: "",
      state: "",
      stateValue: "",
      city: "",
      cityValue: "",
      province: "",
      zip: "",
      postal_code: "",
      formatted_address: "",
      lat: "",
      lng: "",
    },
    phone: {
      number: "",
      code: "",
      dialcountry: "",
    },
    contactPhone: {
      number: "",
      code: "",
      dialcountry: "",
    },
  };

  componentDidMount() {
    window.scroll(0, 0);
    this.getGeneralSettingsAPI();
  }
  getGeneralSettingsAPI = () => {
    request({
      url: "/getGeneralSettings",
      method: "POST",
    }).then((res) => {
      if (res && +res.status === 1) {
        const settings = res.settings;
        this.setState({
          email_gen: settings.email,
          address: settings.address,
          phone: settings.phone,
        });
      }
    });
  };
  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onChangeObject = (e, stateValue) => {
    const re = /^[0-9\b]+$/;

    const name = e.target.name;
    const value = e.target.value;

    if (value === "" || re.test(value)) {
      // Allow only numbers in textbox
      var max_chars = 1;
      var num = String(value);

      stateValue[name] = num;
      this.setState({
        stateValue,
      });
    }
  };

  onContactSubmit = (e) => {
    const { name, email, contactPhone, subject, message } = this.state;
    if (email === "")
      return toast.error("Email required.", {
        id: "email",
      });
    if (contactPhone.number === "")
      return toast.error("Phone required.", {
        id: "email",
      });
    request({
      url: "/contactus",
      method: "POST",
      data: {
        phone: contactPhone,
        name,
        email,
        subject,
        message,
      },
    }).then((res) => {
      if (res.status === 1) {
        toast.success("Thanks for contacting us.", {
          id: "email",
        });
        this.setState({
          name: "",
          email: "",
          contactPhone: {
            number: "",
            code: "",
            dialcountry: "",
          },
          subject: "",
          message: "",
        });
      } else if (res.status === 0) {
        toast.error(res.response, {
          id: "email",
        });
      }
    });
  };

  render() {
    const {
      name,
      email,
      subject,
      message,
      phone,
      contactPhone,
      copyright,
      address,
      email_gen,
    } = this.state;

    return (
      <div>
        <HeaderOthers />
        <div className="page-wrapper contactus">
          <Toaster
            position="bottom-center"
            reverseOrder={false}
            containerClassName=""
            containerStyle={{}}
            toastOptions={{
              // Define default options

              // Default options for specific types
              success: {
                duration: 5000,
                theme: {
                  primary: "green",
                  secondary: "black",
                },
                style: {
                  background: "#363636",
                  color: "#fff",
                  width: "100%",
                },
              },
              error: {
                duration: 3000,
                theme: {
                  primary: "green",
                  secondary: "black",
                },
                style: {
                  background: "#363636",
                  color: "#fff",
                  width: "100%",
                },
              },
            }}
          />
          <section className="hero-section-contactus">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-3">
                  <div className="contacts-head">
                    <Link to="/">Home</Link>
                    <Link to="/contactus">
                      <i className="fa fa-circle" aria-hidden="true"></i>{" "}
                      Contact Us
                    </Link>
                  </div>
                </div>
                <div className="offset-md-1 text-center col-md-4">
                  <div className="content-box">
                    <h2>Contact us</h2>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="contact-section">
            <div className="container">
              <div className="row">
                <div className="col-md-5 text-left mb-3">
                  <div className="head-box">
                    <h6>Have a work in mind?</h6>
                    <h2>Hi, Lets Meet</h2>
                  </div>
                </div>
                <div className="offset-md-2 col-md-5 text-right mb-3">
                  <div className="social-icons d-flex align-items-center">
                    <div className="d-inline-block mr-3">
                      <a
                        href={`tel:+27 ${phone.number}`}
                        className="contact-icon text-decoration-none"
                      >
                        <i
                          className="fa fa-phone text-decoration-none text-white"
                          aria-hidden="true"
                        ></i>
                        <span className="text-reset">+27 {phone.number}</span>
                      </a>
                    </div>
                    <div className="facebook d-inline-block mr-3">
                      <a href="">
                        <img className="img-fluid" src={FacebookIcon} />
                      </a>
                    </div>
                    <div className="twitter d-inline-block mr-3">
                      <a href="">
                        <img className="img-fluid" src={TwitterIcon} />
                      </a>
                    </div>
                    <div className="twitter d-inline-block mr-3">
                      <a href="">
                        <img className="img-fluid" src={InstagramIcon} />
                      </a>
                    </div>
                    <div className="twitter d-inline-block mr-3">
                      <a href="">
                        <img className="img-fluid" src={LinkedinIcon} />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className="form-box">
                      <input
                        className="form-control"
                        id="name"
                        type="text"
                        name="name"
                        value={name}
                        onChange={this.onChange}
                        placeholder="Name"
                      />
                      <i className="fa fa-user" aria-hidden="true"></i>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className="form-box">
                      <input
                        type="email"
                        className="form-control"
                        aria-describedby="emailHelp"
                        placeholder="Email Address"
                        name="email"
                        value={email}
                        onChange={this.onChange}
                      />
                      <i className="fa fa-envelope" aria-hidden="true"></i>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className="form-box">
                      <input
                        className="form-control"
                        id="phone"
                        placeholder="Mobile Number"
                        name="number"
                        type="text"
                        maxlength="10"
                        value={contactPhone.number}
                        onChange={(e) => this.onChangeObject(e, contactPhone)}
                      />
                      <i className="fa fa-phone" aria-hidden="true"></i>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className="form-box">
                      <input
                        className="form-control"
                        id="subject"
                        type="text"
                        name="subject"
                        value={subject}
                        onChange={this.onChange}
                        placeholder="Subject"
                      />
                      <i className="fa fa-user" aria-hidden="true"></i>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-12 text-center">
                    <div className="form-group message">
                      <textarea
                        className="form-control"
                        placeholder="Message"
                        id="exampleFormControlTextarea1"
                        rows="5"
                        name="message"
                        value={message}
                        onChange={this.onChange}
                      ></textarea>
                    </div>
                    <Link
                      className="btn btn-light text-center"
                      onClick={this.onContactSubmit}
                    >
                      Submit
                    </Link>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="img-box">
                    <img className="img-fluid" src={ContactUsImg} />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="middle-section">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-4 mb-3 d-inline-block">
                  <div className="content-box">
                    <div
                      classNameName="wow fadeInDown"
                      data-wow-duration="2s"
                      data-wow-delay="1s"
                    >
                      <div className="d-flex align-items-center">
                        <i className="fa fa-map-marker" aria-hidden="true"></i>
                        <p>
                          {address.line1},{address.line2}, {address.city},{" "}
                          {address.postal_code}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 mb-3 d-inline-block">
                  <div className="content-box">
                    <div
                      classNameName="wow fadeInDown"
                      data-wow-duration="2s"
                      data-wow-delay="1s"
                    >
                      <div className="d-flex align-items-center">
                        <i className="fa fa-envelope" aria-hidden="true"></i>
                        <p>{email_gen} </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 mb-3 d-inline-block">
                  <div className="content-box ">
                    <div
                      classNameName="wow fadeInDown"
                      data-wow-duration="2s"
                      data-wow-delay="1s"
                    >
                      <div className="d-flex align-items-center">
                        <i className="fa fa-phone" aria-hidden="true"></i>
                        <p>+27 {phone.number}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="m-0 p-0">
            <div className="maps">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1393.0967708088613!2d18.518471776271994!3d-33.85458147300864!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1dcc5f8fa5371487%3A0x9f89666af8f6c20b!2sJarin%20Park!5e0!3m2!1sen!2sin!4v1621168654314!5m2!1sen!2sin"
                width="100%"
                height="450"
                styles="border:0;"
                allowfullscreen=""
                loading="lazy"
              ></iframe>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default ContactUs;
