import React, { Component } from "react";
import Dropzone from "react-dropzone";
import toast, { Toaster } from "react-hot-toast";
import request from "../../../api/api";
import "./quotes.css";
// import Upload from "../../../img/upload (1)@3x.png";
// import DeleteIcon from "../../../img/delete(3)@3x.png";
// import FormDemo from "../../../img/form.png";

import Select from "react-select";

import HeaderOthers from "../Common/HeaderOthers";

import jwt_decode from "jwt-decode";
import { Link } from "react-router-dom";

import { capetownSuburbs } from "../../../Json/CapetownSurbubs";

import ModalUser from "../Common/Modal";

/* React Select */

const colourStyles = {
  control: (styles, { isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: "white",
    cursor: "pointer",
    position: "relative",
    zIndex: "99",
    borderColor: isSelected ? "#968c8c" : "#cccccc",
    boxShadow: isSelected ? "#000000" : "",
    ":hover": {
      borderColor: "#000000",
      boxShadow: "#000000",
    },

    ":focus": {
      borderColor: "#000000",
    },
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      cursor: "pointer",
      backgroundColor: isFocused ? "#000000" : "white",
      color: isFocused ? "white" : "",
      ":hover": {
        backgroundColor: "#000000",
        color: "white",
      },
    };
  },
  placeholder: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      fontSize: "14px",
    };
  },
};
/* React Select */

export default class Quotes extends Component {
  state = {
    service_name:
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.service,
    isLawyerAppModal: false,
    isQuote: false,
    userData: "",
    type: "guest",
    mainCategory: "",
    mainCategoryName: "",
    name: "",
    price: "",
    pricePer: "",
    status: "",
    subCategory: "",
    subCategoryName: "",

    mainCategoryListselect: [],
    mainCategorySelect: "",
    mainCategorySelectValue: "",

    subCategoryListSelect: [],
    subCategorySelect: "",
    subCategorySelectValue: "",

    productListSelect: [],
    productList: "",
    productListValue: "",

    coloursListSelect: [],

    primary_colourID: "",

    coloursSelect: "",
    coloursSelectValue: "",

    secondaryColoursSelect: "",
    secondaryColoursSelectValue: "",

    meters: 0,
    running_meters: false,
    square_meters: false,
    type_sink: {
      drop_in: false,
      undermounted: false,
      butter: false,
      on_top: false,
      other: false,
      none: false,
    },
    extras: {
      splashback: false,
      kickplates: false,
      hob_clabbing: false,
    },
    contact: {
      line1: "",
      line2: "",
      province: "",
      city: "",
      postal_code: "",
    },
    remove_countertops: "",
    install_groundfloor: "",
    isDesignPlan: "",
    notes: "",
    message: "",

    pictures: [],
    any_details: "",

    suburb: "",
    suburbValue: "",
    suburbSelect: [],

    city: "",
    cityValue: "",
    citySelect: [
      {
        value: "Cape Town",
        label: "Cape Town",
      },
    ],

    province: "",
    provinceValue: "",
    provinceSelect: [
      {
        value: "Western Cape",
        label: "Western Cape",
      },
    ],

    project_urgency: "",
    project_urgencyValue: "",
    project_urgencySelect: [
      {
        value: "Within 1 - 2 weeks",
        label: "Within 1 - 2 weeks",
      },
      {
        value: "Within 1 month",
        label: "Within 1 month",
      },
      {
        value: "More than a month",
        label: "More than a month",
      },
      {
        value: "Planning and budgeting",
        label: "Planning and budgeting",
      },
    ],
    localData:
      localStorage.getItem("KGQS") !== null
        ? localStorage.getItem("KGQS")
        : null,
  };
  componentDidMount() {
    window.scroll(0, 0);
    window.onload = () => {
      localStorage.removeItem("KGQS");
    };

    const checkLocalStorage = localStorage.getItem("KGQS");

    this.settingSuburbs();
    this.categoryList();
    this.coloursList();
    this.getProductsAPI();
    this.checkUser();

    if (checkLocalStorage !== null) {
      this.loadLocalStorage(checkLocalStorage);
    }
  }
  settingSuburbs = (checkLocalStorage) => {
    const suburbSelect = capetownSuburbs.map((item) => {
      return {
        value: item.surbub,
        label: item.surbub,
        postal_code: item.postal_code,
      };
    });

    this.setState({
      suburbSelect,
    });
  };
  loadLocalStorage = (checkLocalStorage) => {
    this.setState(
      {
        quoteData: JSON.parse(checkLocalStorage),
      },
      () => {
        this.getQuoteData();
      }
    );
  };
  getQuoteData = () => {
    const {
      mainCategorySelect,
      subCategorySelect,
      coloursSelect,
      secondaryColoursSelect,

      primary_colourID,
      productList,
      productListValue,
      secondary_colourID,

      mainCategorySelectValue,
      subCategorySelectValue,
      coloursSelectValue,
      secondaryColoursSelectValue,

      suburb,
      suburbValue,

      city,
      cityValue,

      province,
      provinceValue,

      materialName,
      brandName,
      service_name,
      meters,
      primary_colour,
      secondary_colour,
      type_sink,
      extras,
      remove_countertops,
      install_groundfloor,
      isDesignPlan,
      project_urgency,
      contact,
      any_details,
      notes,
      quoted_amount,
      remarks,
      running_meters,
      square_meters,
      status,
      user,
      project_urgencyValue,
    } = this.state.quoteData;

    this.setState({
      mainCategorySelect,
      subCategorySelect,
      coloursSelect,
      secondaryColoursSelect,

      suburb,
      suburbValue,

      city,
      cityValue,

      province,
      provinceValue,

      primary_colourID,
      productList,
      productListValue,
      secondary_colourID,

      mainCategorySelectValue,
      subCategorySelectValue,
      coloursSelectValue,
      secondaryColoursSelectValue,

      user,
      status,
      materialName,
      brandName,
      service_name,
      meters,
      primary_colour,
      secondary_colour,
      type_sink,
      extras,
      remove_countertops,
      install_groundfloor,
      isDesignPlan,
      project_urgency,
      contact,
      any_details,
      notes,
      quoted_amount,
      remarks,
      running_meters,
      square_meters,
      project_urgencyValue,
    });
  };
  enableBeforeUnload = () => {
    window.onbeforeunload = (e) => {
      return true;
    };
  };
  disableBeforeUnload() {
    window.onbeforeunload = null;
  }
  componentWillUnmount() {
    this.disableBeforeUnload();

    if (this.props.history.location.pathname !== "/quote/summary") {
      localStorage.removeItem("KGQS");
    }
  }

  getProductsAPI = () => {
    request({
      url: "/site/list/all/products",
      method: "POST",
    }).then((res) => {
      if (res && +res.status === 1) {
        let productListSelect = res.response.result.map((item) => ({
          value: item._id,
          label: item.name,
        }));

        this.setState(
          {
            productListSelect,
          },
          () => {
            if (
              this.props &&
              this.props.location &&
              this.props.location.state &&
              this.props.location.state.product_id &&
              this.props.location.state.service === ""
            ) {
              this.setState({
                productList: this.props.location.state.product_id,
                productListValue: productListSelect.filter(
                  (item) => item.value === this.props.location.state.product_id
                ),
              });
            }
          }
        );
      } else if (res && +res.status === 0) {
        this.setState({
          productListSelect: [],
        });
      }
    });
  };
  getFilterdProductsAPI = (mainCategorySelect, subCategorySelect, colours) => {
    const { id, type } = this.state;
    request({
      url: "/site/list/filter/products",
      method: "POST",
      data: {
        id,
        type,
        mainCategory: mainCategorySelect,
        subCategory: subCategorySelect,
        colours: colours,
      },
    }).then((res) => {
      if (res && +res.status === 1) {
        let productListSelect = res.response.result.map((item) => ({
          value: item._id,
          label: item.name,
        }));

        this.setState({
          productListSelect,
        });
      } else if (res && +res.status === 0) {
        this.setState({
          productListSelect: [],
        });
      }
    });
  };

  categoryList = () => {
    request({
      url: "/admin/product/maincategory/listall",
      method: "POST",
    }).then((res) => {
      if (res.status === 1) {
        this.setState({
          mainCategoryList: res.response,
        });
        let mainCategoryListselect = this.state.mainCategoryList.map(
          (item) => ({
            value: item._id,
            label: item.name,
          })
        );
        this.setState(
          {
            mainCategoryListselect,
          },
          () => {
            if (
              this.props &&
              this.props.location &&
              this.props.location.state &&
              this.props.location.state.main &&
              this.props.location.state.service === ""
            ) {
              // this.getUserAPI(this.props.location.state.id);
              this.setState(
                {
                  mainCategorySelect: this.props.location.state.main,
                  subCategorySelect: this.props.location.state.sub,

                  mainCategorySelectValue: mainCategoryListselect.filter(
                    (item) => item.value === this.props.location.state.main
                  ),
                },
                () => {
                  this.subCategoryList(
                    this.props.location.state.main,
                    this.props.location.state.sub
                  );
                }
              );
            }
          }
        );
      } else if (res.status === 0) {
        // toast.error(res.response);
      }
    });
  };

  coloursList = () => {
    request({
      url: "/site/product/colours/listall",
      method: "POST",
    }).then((res) => {
      if (res.status === 1) {
        let coloursListSelect = res.response.map((item) => ({
          value: item._id,
          label: item.name,
        }));

        this.setState(
          {
            coloursListSelect,
          },
          () => {
            if (
              this.props &&
              this.props.location &&
              this.props.location.state &&
              this.props.location.state.color &&
              this.props.location.state.service === ""
            ) {
              this.setState({
                primary_colourID: this.props.location.state.color,
                coloursSelect: coloursListSelect.filter(
                  (item) => item.value === this.props.location.state.color
                )[0].label,
                coloursSelectValue: coloursListSelect.filter(
                  (item) => item.value === this.props.location.state.color
                ),
              });
            }
          }
        );
      } else if (res.status === 0) {
        toast.error(res.response);
      }
    });
  };

  getUserAPI = (auth) => {
    request({
      url: "/site/get/userdata",
      method: "POST",
      data: {
        user: auth,
      },
    }).then((res) => {
      if (res && +res.status === 1) {
        const userData = res.response;
        this.setState({
          contact: {
            line1: userData.residence.line1,
            line2: userData.residence.line2,
            province: userData.residence.province,
            city: userData.residence.city,
            postal_code: userData.residence.postal_code,
          },

          suburb: userData.residence.line2,
          suburbValue: this.state.suburbSelect.filter(
            (item) => item.value === userData.residence.line2
          ),

          city: userData.residence.city,
          cityValue: this.state.citySelect.filter(
            (item) => item.value === userData.residence.city
          ),

          province: userData.residence.province,
          provinceValue: this.state.provinceSelect.filter(
            (item) => item.value === userData.residence.province
          ),
        });
      } else if (res && +res.status === 0) {
      }
    });
  };
  subCategoryList = (value, get) => {
    request({
      url: "/site/product/subcategory/listall",
      method: "POST",
      data: {
        mainCategoryId: value,
      },
    }).then((res) => {
      if (res.status === 1) {
        let subCategoryListSelect = res.response.map((item) => ({
          value: item._id,
          label: item.name,
        }));

        this.setState(
          {
            subCategoryListSelect,
          },
          () => {
            if (get !== "") {
              this.setState({
                subCategorySelectValue: subCategoryListSelect.filter(
                  (item) => item.value === get
                ),
              });
            }
          }
        );
      } else if (res.status === 0) {
        // toast.error(res.response);
      }
    });
  };
  onSelectCategory = (value) => {
    if (value) {
      this.setState(
        {
          mainCategorySelect: value.value,
          mainCategorySelectValue: Array.of(value),
          subCategorySelect: "",
          subCategorySelectValue: "",
          productList: "",
          productListValue: "",
        },
        () => {
          this.subCategoryList(value.value, "");
          this.getFilterdProductsAPI(value.value, undefined, "");
        }
      );
    } else {
      this.setState({
        mainCategorySelect: "",
        mainCategorySelectValue: "",
        subCategorySelect: "",
        subCategorySelectValue: "",
        subCategoryListSelect: [],
      });
      this.getFilterdProductsAPI(undefined, undefined, "");
    }
  };
  onSelectSubCategory = (valueType) => {
    if (valueType) {
      const value = valueType.value;
      const array = Array.of(valueType);

      this.setState({
        subCategorySelect: value,
        subCategorySelectValue: array,
      });
      this.getFilterdProductsAPI(
        this.state.mainCategorySelect,
        value,
        undefined
      );
    } else {
      this.setState({
        subCategorySelect: "",
        subCategorySelectValue: "",
      });
      this.getFilterdProductsAPI(this.state.mainCategorySelect, "", undefined);
    }
  };
  onSelectProducts = (valueType) => {
    if (valueType) {
      const value = valueType.value;
      const array = Array.of(valueType);

      this.setState({
        productList: value,
        productListValue: array,
      });
    } else {
      this.setState({
        productList: "",
        productListValue: "",
      });
    }
  };

  onSelect = (valueType, stateValue, id) => {
    if (valueType) {
      const value = valueType.value;
      const label = valueType.label;
      const array = Array.of(valueType);

      this.setState({
        [stateValue]: label,
        [id]: value,
        [stateValue + "Value"]: array,
      });
    } else {
      this.setState({
        [stateValue]: "",
        [stateValue + "Value"]: "",
      });
    }
  };
  onSelectAddress = (valueType, stateValue, contact) => {
    if (valueType) {
      const value = valueType.value;
      const label = valueType.label;
      const array = Array.of(valueType);

      if (stateValue === "suburb") {
        contact["postal_code"] = valueType.postal_code;
        contact["line2"] = valueType.value;
      }

      contact[stateValue] = value;
      this.setState({
        stateValue,
      });

      this.setState({
        [stateValue]: label,
        [stateValue + "Value"]: array,
      });
    } else {
      this.setState({
        [stateValue]: "",
        [stateValue + "Value"]: "",
      });
    }
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  onCheckedChange = (e) => {
    e.persist();
    this.setState({
      [e.target.name]: e.target.checked,
    });
  };

  onChangeObject = (e, stateValue) => {
    const name = e.target.name;
    const value = e.target.value;
    stateValue[name] = value;
    this.setState({
      stateValue,
    });
  };
  onChangeObjectChecked = (e, stateValue) => {
    const name = e.target.name;
    const value = e.target.checked;
    stateValue[name] = value;
    this.setState({
      stateValue,
    });
  };

  checkUser = () => {
    const getData = localStorage.getItem("KGU");

    if (getData) {
      const token = jwt_decode(getData);

      this.getUserAPI(token._id);

      if (token) {
        this.setState({
          userData: token,
        });
      }
    }

    if (getData !== null) {
      this.setState({
        type: "user",
      });
    }
  };

  onFileUpload = (files) => {
    const file_data = files[0];
    const file_split = file_data.type.split("/");
    if (files.length === 1) {
      if (
        file_split[1] === "pdf" ||
        file_split[1] === "png" ||
        file_split[1] === "jpeg" ||
        file_split[1] === "jpg"
      ) {
        if (file_data.size < 5242880) {
          this.files_api(file_data || null);
        } else {
          toast.error("Maximum upload size is 5MB");
        }
      } else {
        toast.error("File Format not supported");
      }
    } else {
      toast.error("Please add multiple files one by one");
    }
  };

  files_api(uploadfiles) {
    const data = new FormData();
    data.append("pictures", uploadfiles);

    request({
      url: "/site/quotes/save/local/images",
      method: "POST",
      data,
    }).then((res) => {
      if (res.status === 1) {
        this.setState((prevState) => ({
          pictures: [
            ...prevState.pictures,
            {
              path: res.file_path,
              name: uploadfiles.name,
            },
          ],
        }));
      } else if (res.status === 0) {
        this.setState({ pictures: [] });
      }
    });
  }

  checkValue = (array) => {
    const value = (obj) =>
      obj.type === "pdf" ||
      obj.type === "png" ||
      obj.type === "jpeg" ||
      obj.type === "jpg";

    return array.some(value);
  };

  removeClick(e, i, id) {
    e.stopPropagation();
    e.preventDefault();
    if (id) {
      request({
        url: "/admin/delete/product/picture",
        method: "POST",
        data: {
          id: this.state.id,
          pic_id: id,
        },
      }).then((res) => {
        if (res.status === 1) {
          const pictures = [...this.state.pictures];
          pictures.splice(i, 1);
          this.setState({ pictures });
        } else if (res.status === 0) {
          const pictures = [...this.state.pictures];
          pictures.splice(i, 1);
          this.setState({ pictures });
        }
      });
    } else {
      const pictures = [...this.state.pictures];
      pictures.splice(i, 1);
      this.setState({ pictures });
    }
  }

  toggle = () => this.setState({ modal: false });

  summaryPage = () => {
    const {
      primary_colourID,
      productList,
      productListValue,
      secondary_colourID,
      pictures,
      any_details,
      userData,
      type,
      meters,
      running_meters,
      square_meters,
      type_sink,
      extras,
      remove_countertops,
      install_groundfloor,
      isDesignPlan,
      notes,
      project_urgency,
      project_urgencyValue,
      contact,
      message,
      service_name,

      mainCategorySelect,
      subCategorySelect,
      coloursSelect,
      secondaryColoursSelect,

      suburb,
      suburbValue,

      city,
      cityValue,

      province,
      provinceValue,

      mainCategorySelectValue,
      subCategorySelectValue,
      coloursSelectValue,
      secondaryColoursSelectValue,
    } = this.state;

    const data = {
      userData: userData._id,
      type,
      meters,
      running_meters,
      square_meters,
      type_sink,
      extras,
      remove_countertops,
      install_groundfloor,
      isDesignPlan,
      notes,
      project_urgency,
      project_urgencyValue,
      contact,
      message,
      primary_colourID,
      productList,
      productListValue,
      secondary_colourID,
      pictures,
      any_details,
      service_name,

      suburb,
      suburbValue,

      city,
      cityValue,

      province,
      provinceValue,

      mainCategorySelect,
      subCategorySelect,
      coloursSelect,
      secondaryColoursSelect,

      mainCategorySelectValue,
      subCategorySelectValue,
      coloursSelectValue,
      secondaryColoursSelectValue,
    };

    localStorage.setItem("KGQS", JSON.stringify(data));

    this.props.history.push({
      pathname: "/quote/summary",
    });
  };
  onSubmitQuotesInformation = () => {
    this.checkQuoteData();
  };

  checkQuoteData = () => {
    const { contact, productList, service_name } = this.state;

    if (service_name === "" || service_name === undefined) {
      if (productList === "")
        return toast.error("Stone name required.", {
          id: "submit",
        });
    }

    if (contact.line1 === "")
      return toast.error("Address required.", {
        id: "submit",
      });
    if (contact.line2 === "")
      return toast.error("Line 2 required.", {
        id: "submit",
      });
    if (contact.province === "")
      return toast.error("Province required.", {
        id: "submit",
      });
    if (contact.city === "")
      return toast.error("City required.", {
        id: "submit",
      });
    if (contact.postal_code === "")
      return toast.error("Postal Code required.", {
        id: "submit",
      });
    this.onCheckUser();
  };

  onCheckUser = () => {
    const { userData } = this.state;

    // if no user
    if (userData === "") {
      return this.setState({
        isLawyerAppModal: true,
        isQuote: true,
      });
    }

    this.quoteSubmitAPI();
  };

  quoteSubmitAPI = (modal, user, typeUser) => {
    const {
      primary_colourID,
      productList,
      secondary_colourID,
      pictures,
      any_details,
      userData,
      type,
      meters,
      running_meters,
      square_meters,
      type_sink,
      extras,
      remove_countertops,
      install_groundfloor,
      isDesignPlan,
      notes,
      project_urgency,
      contact,
      message,
      service_name,
      mainCategorySelect,
      subCategorySelect,
      coloursSelect,
      secondaryColoursSelect,
    } = this.state;

    const data = {
      userData: userData._id,
      type,
      meters,
      running_meters,
      square_meters,
      type_sink,
      extras,
      remove_countertops,
      install_groundfloor,
      isDesignPlan,
      notes,
      project_urgency,
      contact,
      message,
      primary_colourID,
      productList,
      secondary_colourID,
      pictures: JSON.stringify(pictures),
      any_details,
      service_name,
    };
    if (modal === "user") {
      data.userData = user._id;
      data.type = typeUser;
    }
    if (service_name === "" || service_name === undefined) {
      data.primary_colour = coloursSelect;
      data.secondary_colour = secondaryColoursSelect;
      data.mainCategory = mainCategorySelect;
      data.subCategory = subCategorySelect;
    }

    request({
      url: "/site/save/quotes",
      method: "POST",
      data,
    }).then((res) => {
      if (res && +res.status === 1) {
        toast.success("Quote Submitted !", {
          id: "submit",
        });

        window.location.pathname = "/user/quotes";

        localStorage.removeItem("KGQS");

        if (userData === "") {
          this.setState({
            isLawyerAppModal: true,
          });
        }

        this.setState(
          {
            isLawyerAppModal: false,
            mainCategory: "",
            mainCategoryName: "",

            mainCategorySelect: "",
            mainCategorySelectValue: "",

            subCategorySelect: "",
            subCategorySelectValue: "",

            primary_colourID: "",
            productList: "",
            secondary_colourID: "",

            coloursSelect: "",
            coloursSelectValue: "",

            secondaryColoursSelect: "",
            secondaryColoursSelectValue: "",

            meters: "",
            running_meters: false,
            square_meters: false,
            type_sink: {
              drop_in: false,
              undermounted: false,
              butter: false,
              on_top: false,
              other: false,
              none: false,
            },
            extras: {
              splashback: false,
              kickplates: false,
              hob_clabbing: false,
            },
            contact: {
              first_name: "",
              last_name: "",
              mobile: {
                code: "",
                number: "",
                dialcountry: "",
              },
              email: "",
              city: "",
              preferred_contact: "",
            },
            remove_countertops: "",
            install_groundfloor: "",
            isDesignPlan: "",
            notes: "",
            message: "",

            project_urgency: "",
            project_urgencyValue: "",
          },
          () => {
            if (modal === "user") {
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            }
          }
        );
        this.componentDidMount();
      } else if (res && +res.status === 0) {
        toast.error("Update error !.");
      }
    });
  };

  onOpenLawyerAppModal = () => {
    this.setState({
      isLawyerAppModal: !this.state.isLawyerAppModal,
      email: "",
      modalState: "",
      otp1: "",
      otp2: "",
      otp3: "",
      otp4: "",
    });
  };

  isEmpty = (obj) => {
    return Object.keys(obj).length === 0;
  };

  render() {
    const {
      isQuote,
      isLawyerAppModal,
      service_name,
      any_details,
      pictures,
      meters,
      running_meters,
      square_meters,
      type_sink,
      extras,
      remove_countertops,
      install_groundfloor,
      notes,
      project_urgencySelect,
      project_urgencyValue,
      suburbSelect,
      suburbValue,
      citySelect,
      cityValue,
      provinceSelect,
      provinceValue,
      isDesignPlan,
      contact,
      mainCategoryListselect,
      mainCategorySelectValue,
      subCategoryListSelect,
      subCategorySelectValue,
      coloursListSelect,
      coloursSelectValue,
      productListSelect,
      productListValue,
    } = this.state;

    return (
      <>
        <HeaderOthers />
        <div className="page-wrapper quotespage">
          <Toaster
            position="bottom-center"
            reverseOrder={false}
            containerClassName=""
            containerStyle={{}}
            toastOptions={{
              // Define default options

              // Default options for specific types
              duration: 3000,
              style: {
                background: "#363636",
                color: "#fff",
                width: "100%",
              },
            }}
          />
          <section className="hero-section-quotes">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-3">
                  <div className="homepage">
                    <Link to="/">Home</Link>
                    <Link to="/quotes">
                      <i className="fa fa-circle" aria-hidden="true"></i> Quote
                      Request
                    </Link>
                  </div>
                </div>
                <div className="text-center col-md-6">
                  <div className="content-box">
                    <h2>Request a Quote</h2>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className="container">
              <div id="accordion">
                <div className="card mb-5">
                  <div className="card-header" id="headingOne">
                    <h5 className="mb-0">
                      <button
                        className="btn w-100 text-left btn-link accordion-button"
                        data-toggle="collapse"
                        data-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        <h6>
                          Step - 1 <span>Material Selection</span>
                        </h6>
                      </button>
                    </h5>
                  </div>

                  <div
                    id="collapseOne"
                    className="collapse show"
                    aria-labelledby="headingOne"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <div className="container-fluid">
                        {service_name === "" || service_name === undefined ? (
                          <div className="row">
                            <div className="col-md-3 mt-1">
                              <h5>Colour</h5>
                              <div className="dropdown" id="colors">
                                <Select
                                  name="colours"
                                  isClearable={true}
                                  isSearchable={true}
                                  options={coloursListSelect}
                                  cacheOptions
                                  placeholder="Select Color"
                                  styles={colourStyles}
                                  value={coloursSelectValue}
                                  onChange={(e) =>
                                    this.onSelect(
                                      e,
                                      "coloursSelect",
                                      "primary_colourID"
                                    )
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-3 mt-1">
                              <h5>Choose Your Material</h5>
                              <div className="dropdown" id="materials">
                                <Select
                                  name="mainCategory"
                                  isClearable={true}
                                  isSearchable={true}
                                  options={mainCategoryListselect}
                                  cacheOptions
                                  placeholder="Select Material"
                                  styles={colourStyles}
                                  value={mainCategorySelectValue}
                                  onChange={(e) => this.onSelectCategory(e)}
                                />
                              </div>
                            </div>
                            <div className="col-md-3 mt-1">
                              <h5>Chose your Type</h5>
                              <div className="dropdown" id="types">
                                <Select
                                  name="subCategory"
                                  isClearable={true}
                                  isSearchable={true}
                                  options={subCategoryListSelect}
                                  cacheOptions
                                  placeholder="Select Type"
                                  styles={colourStyles}
                                  value={subCategorySelectValue}
                                  onChange={(e) => this.onSelectSubCategory(e)}
                                />
                              </div>
                            </div>

                            <div className="col-md-3">
                              <h5>
                                Chose Your Stone{" "}
                                <span style={{ color: "red" }}>*</span>
                              </h5>
                              <div
                                className="dropdown redast-custom-1"
                                id="stones"
                              >
                                <Select
                                  name="colours"
                                  isClearable={true}
                                  isSearchable={true}
                                  options={productListSelect}
                                  cacheOptions
                                  placeholder="Select Stone"
                                  styles={colourStyles}
                                  value={productListValue}
                                  onChange={(e) => this.onSelectProducts(e)}
                                />
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="row">
                            <div className="col-md-4 mt-3">
                              <h5>
                                Service Name{" "}
                                <span style={{ color: "red" }}>*</span>
                              </h5>
                              <div className="form-box">
                                <input
                                  className="form-control"
                                  type="text"
                                  placeholder="Type Here"
                                  name="service_name"
                                  value={service_name}
                                  onChange={this.onChange}
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                        )}

                        <div className="row align-items-center">
                          <div className="col-md-4 mt-3">
                            <h5>How many meters do you require?</h5>
                            <div className="form-box">
                              <input
                                className="form-control"
                                type="number"
                                placeholder="Type Here"
                                name="meters"
                                value={meters}
                                onChange={this.onChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="d-flex mt-4">
                              <div className="col-md-6 pl-0 d-inline-block">
                                <div
                                  className="
                            custom-control
                            d-flex
                            align-items-center
                            custom-checkbox
                            mb-3
                          "
                                >
                                  <input
                                    type="checkbox"
                                    name="running_meters"
                                    checked={running_meters}
                                    onChange={this.onCheckedChange}
                                  />
                                  <label className="ml-3" for="check">
                                    Running Meters
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-6 pl-0 d-inline-block">
                                <div
                                  className="
                            custom-control
                            d-flex
                            align-items-center
                            custom-checkbox
                            mb-3
                          "
                                >
                                  <input
                                    type="checkbox"
                                    name="square_meters"
                                    checked={square_meters}
                                    onChange={this.onCheckedChange}
                                  />
                                  <label className="ml-3" for="check">
                                    Square Meters
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12 text-center">
                            <div className="next-btn">
                              <button
                                className="btn btn-light"
                                id="headingTwo"
                                data-toggle="collapse"
                                data-target="#collapseTwo"
                                aria-expanded="true"
                                aria-controls="collapseTwo"
                              >
                                Next
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card mb-5">
                  <div className="card-header" id="headingTwo">
                    <h5 className="mb-0">
                      <button
                        className="btn w-100 text-left btn-link accordion-button"
                        data-toggle="collapse"
                        data-target="#collapseTwo"
                        aria-expanded="true"
                        aria-controls="collapseTwo"
                      >
                        <h6>
                          Step - 2 <span>Fabrication & Installation</span>
                        </h6>
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapseTwo"
                    className="collapse"
                    aria-labelledby="headingTwo"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <div className="container-fluid">
                        <h5>Type of Sink</h5>
                        <div className="row">
                          <div className="col-md-12 mt-3 pl-0 d-lg-flex">
                            <div className="col pl-0 d-inline-block">
                              <div
                                className="
                          custom-control
                          d-flex
                          align-items-center
                          custom-checkbox
                          mb-3
                        "
                              >
                                <input
                                  type="checkbox"
                                  name="drop_in"
                                  checked={type_sink.drop_in}
                                  onChange={(e) =>
                                    this.onChangeObjectChecked(e, type_sink)
                                  }
                                />
                                <label className="ml-3" for="check">
                                  Drop In
                                </label>
                              </div>
                            </div>
                            <div className="col pl-0 d-inline-block">
                              <div
                                className="
                          custom-control
                          d-flex
                          align-items-center
                          custom-checkbox
                          mb-3
                        "
                              >
                                <input
                                  type="checkbox"
                                  name="undermounted"
                                  checked={type_sink.undermounted}
                                  onChange={(e) =>
                                    this.onChangeObjectChecked(e, type_sink)
                                  }
                                />
                                <label className="ml-3" for="check">
                                  Undermounted
                                </label>
                              </div>
                            </div>
                            <div className="col pl-0 d-inline-block">
                              <div
                                className="
                          custom-control
                          d-flex
                          align-items-center
                          custom-checkbox
                          mb-3
                        "
                              >
                                <input
                                  type="checkbox"
                                  name="butter"
                                  checked={type_sink.butter}
                                  onChange={(e) =>
                                    this.onChangeObjectChecked(e, type_sink)
                                  }
                                />
                                <label className="ml-3" for="check">
                                  Butter
                                </label>
                              </div>
                            </div>
                            <div className="col pl-0 d-inline-block">
                              <div
                                className="
                          custom-control
                          d-flex
                          align-items-center
                          custom-checkbox
                          mb-3
                        "
                              >
                                <input
                                  type="checkbox"
                                  name="on_top"
                                  checked={type_sink.on_top}
                                  onChange={(e) =>
                                    this.onChangeObjectChecked(e, type_sink)
                                  }
                                />
                                <label className="ml-3" for="check">
                                  On top
                                </label>
                              </div>
                            </div>
                            <div className="col pl-0 d-inline-block">
                              <div
                                className="
                          custom-control
                          d-flex
                          align-items-center
                          custom-checkbox
                          mb-3
                        "
                              >
                                <input
                                  type="checkbox"
                                  name="other"
                                  checked={type_sink.other}
                                  onChange={(e) =>
                                    this.onChangeObjectChecked(e, type_sink)
                                  }
                                />
                                <label className="ml-3" for="check">
                                  Other
                                </label>
                              </div>
                            </div>
                            <div className="col pl-0 d-inline-block">
                              <div
                                className="
                          custom-control
                          d-flex
                          align-items-center
                          custom-checkbox
                          mb-3
                        "
                              >
                                <input
                                  type="checkbox"
                                  name="none"
                                  checked={type_sink.none}
                                  onChange={(e) =>
                                    this.onChangeObjectChecked(e, type_sink)
                                  }
                                />
                                <label className="ml-3" for="check">
                                  None
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <h5>Extras</h5>
                        <div className="row">
                          <div className="col-md-12 mt-3 pl-0 d-lg-flex">
                            <div className="col-md-3 pl-0 d-inline-block">
                              <div
                                className="
                          custom-control
                          d-flex
                          align-items-center
                          custom-checkbox
                          mb-3
                        "
                              >
                                <input
                                  type="checkbox"
                                  name="splashback"
                                  checked={extras.splashback}
                                  onChange={(e) =>
                                    this.onChangeObjectChecked(e, extras)
                                  }
                                />
                                <label className="ml-3" for="check">
                                  Splashback 75mm
                                </label>
                              </div>
                            </div>
                            <div className="col-md-3 pl-0 d-inline-block">
                              <div
                                className="
                          custom-control
                          d-flex
                          align-items-center
                          custom-checkbox
                          mb-3
                        "
                              >
                                <input
                                  type="checkbox"
                                  name="kickplates"
                                  checked={extras.kickplates}
                                  onChange={(e) =>
                                    this.onChangeObjectChecked(e, extras)
                                  }
                                />
                                <label className="ml-3" for="check">
                                  Kickplates
                                </label>
                              </div>
                            </div>
                            <div className="col-md-3 pl-0 d-inline-block">
                              <div
                                className="
                          custom-control
                          d-flex
                          align-items-center
                          custom-checkbox
                          mb-3
                        "
                              >
                                <input
                                  type="checkbox"
                                  name="hob_clabbing"
                                  checked={extras.hob_clabbing}
                                  onChange={(e) =>
                                    this.onChangeObjectChecked(e, extras)
                                  }
                                />
                                <label className="ml-3" for="check">
                                  Hob Clabbing
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <h5 className="mt-3 mb-3">
                              Do you want us to remove your existing countertops
                            </h5>
                            <div className="col-md-2 d-inline-block">
                              <input
                                type="radio"
                                id="test1"
                                name="remove_countertops"
                                checked={remove_countertops === "yes"}
                                value="yes"
                                onChange={this.onChange}
                              />
                              <label for="test1">Yes</label>
                            </div>
                            <div className="col-md-2 d-inline-block">
                              <input
                                type="radio"
                                id="test2"
                                name="remove_countertops"
                                checked={remove_countertops === "no"}
                                value="no"
                                onChange={this.onChange}
                              />
                              <label for="test2">No</label>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12 mt-3 d-inline-block text-center">
                            <div className="next-btn d-inline-block mr-3">
                              <button
                                className="btn btn-light"
                                id="headingOne"
                                data-toggle="collapse"
                                data-target="#collapseOne"
                                aria-expanded="true"
                                aria-controls="collapseOne"
                              >
                                Back
                              </button>
                            </div>
                            <div className="next-btn d-inline-block">
                              <button
                                className="btn btn-light"
                                id="headingThree"
                                data-toggle="collapse"
                                data-target="#collapseThree"
                                aria-expanded="false"
                                aria-controls="collapseThree"
                              >
                                Next
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card mb-5">
                  <div className="card-header" id="headingThree">
                    <h5 className="mb-0">
                      <button
                        className="btn w-100 text-left btn-link accordion-button"
                        data-toggle="collapse"
                        data-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        <h6>
                          Step - 3 <span>Site Details</span>
                        </h6>
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapseThree"
                    className="collapse"
                    aria-labelledby="headingThree"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <div className="container-fluid">
                        <h5>Site Address</h5>
                        <div className="row">
                          <div className="col-md-6 mt-3">
                            <div className="form-box d-flex redast-custom">
                              <input
                                className="form-control"
                                type="text"
                                placeholder="Address"
                                name="line1"
                                value={contact.line1}
                                onChange={(e) =>
                                  this.onChangeObject(e, contact)
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-6 mt-3">
                            <div className="dropdown redast" id="suburb">
                              <Select
                                name="suburbValue"
                                isClearable={true}
                                isSearchable={true}
                                options={suburbSelect}
                                cacheOptions
                                placeholder="Suburb"
                                styles={colourStyles}
                                value={suburbValue}
                                onChange={(e) =>
                                  this.onSelectAddress(e, "suburb", contact)
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6 mt-3">
                            <div className="dropdown redast" id="city">
                              <Select
                                name="cityValue"
                                isClearable={true}
                                isSearchable={true}
                                options={citySelect}
                                cacheOptions
                                placeholder="City"
                                styles={colourStyles}
                                value={cityValue}
                                onChange={(e) =>
                                  this.onSelectAddress(e, "city", contact)
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-6 mt-3">
                            <div className="dropdown redast" id="province">
                              <Select
                                name="provinceValue"
                                isClearable={true}
                                isSearchable={true}
                                options={provinceSelect}
                                cacheOptions
                                placeholder="Province"
                                styles={colourStyles}
                                value={provinceValue}
                                onChange={(e) =>
                                  this.onSelectAddress(e, "province", contact)
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6 mt-3">
                            <div className="form-box redast-custom">
                              <input
                                className="form-control"
                                type="text"
                                placeholder="Postal Code"
                                name="postal_code"
                                value={contact.postal_code}
                                onChange={(e) =>
                                  this.onChangeObject(e, contact)
                                }
                              />
                            </div>
                          </div>
                        </div>

                        <h5 className="mt-3">Accessibility</h5>
                        <div className="row">
                          <div className="col-md-6">
                            <h5 className="mt-2 mb-3">
                              Is the place of installation on the ground floor
                              with nearby parking and good access?
                            </h5>
                            <div className="col-md-2 d-inline-block">
                              <input
                                type="radio"
                                id="test3"
                                checked={install_groundfloor === "yes"}
                                name="install_groundfloor"
                                value="yes"
                                onChange={this.onChange}
                              />
                              <label for="test3">Yes</label>
                            </div>
                            <div className="col-md-2 d-inline-block">
                              <input
                                type="radio"
                                id="test4"
                                name="install_groundfloor"
                                checked={install_groundfloor === "no"}
                                value="no"
                                onChange={this.onChange}
                              />
                              <label for="test4">No</label>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12 mt-3">
                            <h5>Notes & Special Requirements</h5>
                            <div>
                              <textarea
                                rows="6"
                                cols="100"
                                form="usrform"
                                name="notes"
                                value={notes}
                                onChange={this.onChange}
                              ></textarea>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12 mt-3 d-inline-block text-center">
                            <div className="next-btn d-inline-block mr-3">
                              <button
                                className="btn btn-light"
                                id="headingTwo"
                                data-toggle="collapse"
                                data-target="#collapseTwo"
                                aria-expanded="false"
                                aria-controls="collapseTwo"
                              >
                                Back
                              </button>
                            </div>
                            <div className="next-btn d-inline-block">
                              <button
                                className="btn btn-light"
                                id="headingFour"
                                data-toggle="collapse"
                                data-target="#collapseFour"
                                aria-expanded="false"
                                aria-controls="collapseFour"
                              >
                                Next
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingFour">
                    <h5 className="mb-0">
                      <button
                        className="btn w-100 text-left btn-link accordion-button"
                        data-toggle="collapse"
                        data-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                      >
                        <h6>
                          Step - 4 <span>additional information</span>
                        </h6>
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapseFour"
                    className="collapse"
                    aria-labelledby="headingFour"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-md-4 mt-3">
                            <h5>Project Urgency</h5>
                            <div className="dropdown">
                              <Select
                                name="project_urgencyValue"
                                isClearable={true}
                                isSearchable={true}
                                options={project_urgencySelect}
                                cacheOptions
                                placeholder="Select"
                                styles={colourStyles}
                                value={project_urgencyValue}
                                onChange={(e) =>
                                  this.onSelect(e, "project_urgency")
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <h5 className="mt-2 mb-3">
                              Do you have a design plan / diagram for your
                              project?
                            </h5>
                            <div className="col-md-2 d-inline-block">
                              <input
                                type="radio"
                                id="test5"
                                checked={isDesignPlan === "yes"}
                                name="isDesignPlan"
                                value="yes"
                                onChange={this.onChange}
                              />
                              <label for="test5">Yes</label>
                            </div>
                            <div className="col-md-2 d-inline-block">
                              <input
                                type="radio"
                                id="test6"
                                name="isDesignPlan"
                                checked={isDesignPlan === "no"}
                                value="no"
                                onChange={this.onChange}
                              />
                              <label for="test6">No</label>
                            </div>
                          </div>
                        </div>

                        {isDesignPlan === "yes" ? (
                          <>
                            <Dropzone
                              onDrop={(acceptedFiles) =>
                                this.onFileUpload(acceptedFiles)
                              }
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div className="row align-items-center">
                                  <div className="col-md-4 d-flex align-items-center">
                                    <h5 className="ml-3 mt-4 d-inline-block">
                                      Upload Files <br />
                                      (Optional)
                                    </h5>
                                    <div
                                      className="dragndrop ml-3 mt-3 btn-browse d-inline-block"
                                      {...getRootProps()}
                                    >
                                      <input {...getInputProps()} />
                                      <p className="blue">Browse</p>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Dropzone>
                            <div className="row">
                              <div className="col-md-3 mt-3 mb-3 text-center">
                                {pictures.length > 0
                                  ? pictures.map((item, i) =>
                                      i < 3 ? (
                                        <h5 key={i}>
                                          {item.name}
                                          <span
                                            className="ml-2 fa fa-close view-hover"
                                            title="remove"
                                            styles={{ cursor: "pointer" }}
                                            onClick={(e) =>
                                              this.removeClick(e, i, item._id)
                                            }
                                          ></span>
                                        </h5>
                                      ) : null
                                    )
                                  : null}
                              </div>
                              <div className="col-md-3 mt-3 mb-3 text-center">
                                {pictures.length > 0
                                  ? pictures.map((item, i) =>
                                      i >= 3 && i < 6 ? (
                                        <h5 key={i}>
                                          {item.name}
                                          <span
                                            className="ml-2 fa fa-close view-hover"
                                            title="remove"
                                            styles={{ cursor: "pointer" }}
                                            onClick={(e) =>
                                              this.removeClick(e, i, item._id)
                                            }
                                          ></span>
                                        </h5>
                                      ) : null
                                    )
                                  : null}
                              </div>
                              <div className="col-md-3 mt-3 mb-3 text-center">
                                {pictures.length > 0
                                  ? pictures.map((item, i) =>
                                      i >= 6 && i < 9 ? (
                                        <h5 key={i}>
                                          {item.name}
                                          <span
                                            className="ml-2 fa fa-close view-hover"
                                            title="remove"
                                            styles={{ cursor: "pointer" }}
                                            onClick={(e) =>
                                              this.removeClick(e, i, item._id)
                                            }
                                          ></span>
                                        </h5>
                                      ) : null
                                    )
                                  : null}
                              </div>
                              <div className="col-md-3 mt-3 mb-3 text-center">
                                {pictures.length > 0
                                  ? pictures.map((item, i) =>
                                      i === 9 ? (
                                        <h5 key={i}>
                                          {item.name}
                                          <span
                                            className="ml-2 fa fa-close view-hover"
                                            title="remove"
                                            styles={{ cursor: "pointer" }}
                                            onClick={(e) =>
                                              this.removeClick(e, i, item._id)
                                            }
                                          ></span>
                                        </h5>
                                      ) : null
                                    )
                                  : null}
                              </div>
                            </div>
                          </>
                        ) : null}

                        <div className="row">
                          <div className="col-md-12 mt-3">
                            <h5>Any Other Details?</h5>
                            <div>
                              <textarea
                                rows="6"
                                cols="100"
                                name="any_details"
                                value={any_details}
                                onChange={this.onChange}
                                form="usrform"
                                className="p-3"
                                placeholder="Type Here"
                              ></textarea>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12 mt-3 text-center">
                            <div className="next-btn">
                              <button
                                className="btn btn-light"
                                onClick={this.summaryPage}
                              >
                                View Summary
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="pt-5 mb-5">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12 text-center">
                  <div>
                    <button
                      className="btn btn-light"
                      onClick={this.onSubmitQuotesInformation}
                    >
                      Submit Request
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {isLawyerAppModal && (
            <ModalUser
              isLawyerAppModal={isLawyerAppModal}
              onOpenLawyerAppModal={this.onOpenLawyerAppModal}
              quoteSubmitAPI={this.quoteSubmitAPI}
              isQuote={isQuote}
              {...this.props}
            />
          )}
        </div>
      </>
    );
  }
}
