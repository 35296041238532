import React, { Component } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";

import "./services.css";

import HeaderOthers from "../Common/HeaderOthers";

// import bgimg from "../../../img/Image60@3x.png";
import BathroomIcon from "../../../img/Path 1330.svg";
import TableTopIcon from "../../../img/Group 201.svg";
import CounterTops from "../../../img/kitchen-sink-with-faucet-silhouette.svg";
import VantitiesIcon from "../../../img/cutting-board.svg";
import DiningIcon from "../../../img/restaurant (3).svg";
import PopupImg from "../../../img/Image 59@3x.png";

import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import request, { NodeURL } from "../../../api/api";

import toast, { Toaster } from "react-hot-toast";
import jwtDecode from "jwt-decode";
import WOW from "wowjs";

export default class Services extends Component {
  state = {
    innerTabs: "",
    activeTab: "1",
    isLawyerAppModal: false,
    serviceList: [],
    noService: [],
    withService: [],
    name: "",
    description: "",
    services: [],
    price: "",
    status: "",
  };

  componentDidMount() {
    new WOW.WOW().init();
    window.scroll(0, 0);
    this.getServicesAPI();
  }

  getServicesAPI = () => {
    const { id, type } = this.state;
    request({
      url: "/site/list/all/services",
      method: "POST",
      data: {
        id,
        type,
      },
    }).then((res) => {
      if (res && +res.status === 1) {
        // setTimeout(() => {
        //   res.response.result.map(
        //     (category) =>
        //       (document.getElementById(
        //         category._id
        //       ).style.backgroundImage = `url(${
        //         NodeURL + "/" + category.pictures
        //       })`)
        //   );
        // }, 2000)

        this.setState(
          {
            isLoader: false,
            serviceList: res.response.result,
            pages: res.response.fullcount,
            currPage: res.response.length,
          },
          () => {
            const noService = res.response.result.filter((item) => {
              if (this.checkValue(item.services) && item.featured === "Yes") {
                return {
                  _id: item._id,
                  description: item.description,
                  price: item.price,
                  status: item.status,
                  name: item.name,
                  services: [],
                  pictures: item.pictures,
                };
              }
            });
            const withService = res.response.result.filter((item) => {
              if (!this.checkValue(item.services) && item.featured === "Yes") {
                return item;
              }
            });
            this.setState(
              {
                noService: noService,
                withService: withService,
              },
              () => {
                this.state.noService.map(
                  (category) =>
                    (document.getElementById(
                      category._id
                    ).style.backgroundImage = `url(${NodeURL + "/"}${
                      category.pictures
                    })`)
                );
                this.state.withService.map(
                  (category) =>
                    (document.getElementById(
                      category._id
                    ).style.backgroundImage = `url(${NodeURL + "/"}${
                      category.pictures
                    })`)
                );
              }
            );
          }
        );
      } else if (res && +res.status === 0) {
        this.setState({
          isLoader: false,
          serviceList: res.response.result,
          pages: res.response.fullcount,
          currPage: res.response.length,
        });
      }
    });
  };

  onPageQuote = (name) => {
    return this.props.history.push({
      pathname: "/quotes",
      state: {
        main: "",
        sub: "",
        service: name,
        // id: auth._id,
      },
    });
  };

  checkValue = (array) => {
    const value = (obj) => obj.name === "";

    return array.some(value);
  };

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }
  onOpenLawyerAppModal = (item, i) => {
    this.setState(
      {
        isLawyerAppModal: !this.state.isLawyerAppModal,
        service_id: item._id,
        name: item.name,
        number: i,
        description: item.description,
        services: item.services,
        pictures: item.pictures,
        price: item.price,
        status: item.status,
      },
      () => {
        // document.getElementById(item._id).style.backgroundImage = `url(http://localhost:8000/uploads/images/admin/service/photo/Kg2Gv1624284934458.png)`;
      }
    );
  };
  onCloseLawyerAppModal = () => {
    this.setState({
      isLawyerAppModal: false,
      name: "",
      number: "",
      description: "",
      services: [],
      price: "",
      status: "",
    });
  };
  render() {
    const {
      serviceList,
      noService,
      withService,
      isLawyerAppModal,
      name,
      service_id,
      description,
      services,
      number,
      pictures,
    } = this.state;

    SwiperCore.use([Autoplay]);
    return (
      <>
        <HeaderOthers />
        <div className="page-wrapper services">
          <Toaster
            position="bottom-center"
            reverseOrder={false}
            containerClassName=""
            containerStyle={{}}
            toastOptions={{
              // Define default options

              // Default options for specific types
              duration: 3000,
              style: {
                background: "#363636",
                color: "#fff",
                width: "100%",
              },
            }}
          />
          <section className="hero-section-services">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-3">
                  <div className="homepage">
                    <Link to="/">Home</Link>
                    <Link to="/services">
                      <i className="fa fa-circle" aria-hidden="true"></i>
                      Services
                    </Link>
                  </div>
                </div>
                <div className="offset-md-1 text-center col-md-4">
                  <div className="content-box">
                    <h2>Our Services</h2>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="offset-md-1 col-md-10">
                  <div className="content-box">
                    <p className="mb-3">
                      We are granite specialists offering an array of granite
                      worktops for your kitchen and bathrooms. We help you
                      rejuvenate your kitchen or bathroom with one-of-a-kind
                      granite countertops. We assure our design solutions will
                      create a soothing effect in your bathrooms. No matter what
                      preference you have, we will supply the granite
                      countertops of your choice. We listen to your ideas,
                      analyze your kitchen or bathroom space and come up with
                      worktops that go well with your environment.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {noService.length > 0
            ? noService.map((item, i) => (
                <section className="body-section-1" key={i} id={item._id}>
                  <div className="container">
                    <div className="row">
                      <div className="offset-md-4 col-md-8 offset-lg-6 col-lg-6">
                        <div className="white-box p-5">
                          <p className="number">{i + 1}</p>
                          <h4>{item.name}</h4>
                          <p className="para-content">{item.description}</p>
                          <button
                            className="btn btn-light"
                            onClick={() => this.onPageQuote(item.name)}
                          >
                            Request a Quote
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              ))
            : null}

          {withService.length > 0
            ? withService.map((item, i) => (
                <section className="body-section-2 p-0" key={i} id={item._id}>
                  <div className="container-fluid p-0">
                    <div className="row no-gutters p-0">
                      <div className="offset-md-2 col-md-6">
                        <div className="bottom-fixed-section">
                          <div className="transparent-bg">
                            <p className="number">02</p>
                            <h4 className="text-white">{item.name}</h4>
                          </div>
                          <div className="bg-white">
                            <p className="para-content">{item.description}</p>
                            <button
                              className="btn btn-light"
                              onClick={() => this.onPageQuote(item.name)}
                            >
                              Request a Quote
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 bg-grey">
                        {/* {item.services.length > 0
                        ? item.services.map((service) => (
                            <div className="offset-md-1 col-md-10 offset-lg-2 col-lg-8">
                              <div className="inner-box">
                                <img className="img-fluid" src={BathroomIcon} />
                                <p>{service.name}</p>
                              </div>
                            </div>
                          ))
                        : null} */}

                        <div className="offset-md-1 col-md-10 offset-lg-2 col-lg-8">
                          <div className="inner-box">
                            <img className="img-fluid" src={TableTopIcon} />
                            <p>Kitchen Table Tops</p>
                          </div>
                        </div>
                        <div className="offset-md-1 col-md-10 offset-lg-2 col-lg-8">
                          <div className="inner-box">
                            <img className="img-fluid" src={CounterTops} />
                            <p>Kitchen Countertops</p>
                          </div>
                        </div>
                        <div className="offset-md-1 col-md-10 offset-lg-2 col-lg-8">
                          <div className="inner-box">
                            <img className="img-fluid" src={VantitiesIcon} />
                            <p>Vanities & Chopping Board</p>
                          </div>
                        </div>
                        <div className="offset-md-1 col-md-10 offset-lg-2 col-lg-8">
                          <div className="inner-box">
                            <img className="img-fluid" src={DiningIcon} />
                            <p>Dining Room Tables</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              ))
            : null}

          <section>
            <div className="container-fluid">
              <div className="row">
                <div className="offset-md-1 col-md-11 p-0">
                  <div className="">
                    <div className="paginationswiper">
                      {serviceList.length > 0 ? (
                        <Swiper
                          pagination={{
                            type: "progressbar",
                            el: ".swiper-pagination-progressbar",
                            clickable: true,
                          }}
                          slidesPerView={5}
                          navigation={true}
                          loop={true}
                          // autoplay={{ delay: 3000 }}
                          onSlideChange={() => console.log("slide change")}
                          onSwiper={(swiper) => console.log(swiper)}
                          breakpoints={{
                            320: {
                              slidesPerView: 1,
                              spaceBetween: 0,
                            },
                            640: {
                              slidesPerView: 1,
                              spaceBetween: 0,
                            },
                            768: {
                              slidesPerView: 3,
                              spaceBetween: 0,
                            },
                            1200: {
                              slidesPerView: 4,
                              spaceBetween: 0,
                            },
                          }}
                        >
                          {serviceList.map((item, i) => (
                            <SwiperSlide key={i}>
                              <div className="">
                                <div className="content">
                                  <div className="item-img bg-img">
                                    <img
                                      className="img1"
                                      id="img1"
                                      src={NodeURL + "/" + item.pictures}
                                    />
                                  </div>
                                  <div className="cont p-4 text-white text-left">
                                    <p className="menu-number">{i + 1}</p>
                                    <h6 className="text-primary">
                                      {item.name}
                                    </h6>
                                    <p className="menu-content">
                                      {item.description}
                                    </p>
                                    <div className="btns">
                                      <button
                                        className="btn btn-dark"
                                        onClick={() =>
                                          this.onOpenLawyerAppModal(item, i)
                                        }
                                      >
                                        View More
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      ) : null}
                    </div>
                    <div className="set-control">
                      <div className="swiper-pagination"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Modal
            className="servicemodal"
            isOpen={isLawyerAppModal}
            toggle={this.onCloseLawyerAppModal}
          >
            <ModalHeader
              className="p-0 pr-md-3"
              toggle={this.onCloseLawyerAppModal}
            >
              <div className="container-fluid">
                <div className="row align-items-center p-0">
                  <div className="col-md-6 p-0 m-0">
                    <div
                      className="modal-img"
                      style={{
                        background: `url(${NodeURL + "/"}${pictures})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                      }}
                      id={service_id}
                    >
                      {/* <img className="img-fluid" src={PopupImg} /> */}
                    </div>
                  </div>
                  <div className="col-md-6">
                    {/* <button type="button" class="close" aria-label="Close">
                    <span aria-hidden="true">×</span>
                  </button> */}
                    <div className="modal-content-box pl-5 pt-2 pb-2 pr-5">
                      <p className="number">{number + 1}</p>
                      <h3>{name}</h3>
                      <p className="pb-md-3">{description}</p>
                      <div className="modal-btn pt-md-3">
                        <button
                          className="btn btn-light"
                          onClick={() => this.onPageQuote(name)}
                        >
                          Request a Quote{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row modal-inner">
                  {services.length > 0
                    ? services.map((item, i) => (
                        <div className="col" key={i}>
                          <div className="inner-box">
                            <img className="img-fluid" src={BathroomIcon} />
                            <p>{item.name}</p>
                          </div>
                        </div>
                      ))
                    : null}
                </div>
              </div>
            </ModalHeader>
            <ModalBody className="p-0"></ModalBody>
          </Modal>
        </div>
      </>
    );
  }
}
