import React, { Component } from "react";
import { Link } from "react-router-dom";
import request from "../../../api/api";
import TermsImg from "../../../img/Accept terms-pana@3x.png";

import "./termsandconditions.css";

import HeaderOthers from "../Common/HeaderOthers";

class TermsAndConditions extends Component {
  state = {
    content: "",
  };
  componentDidMount() {
    window.scroll(0, 0);
    this.getPagesAPI();
  }
  getPagesAPI = (id) => {
    const pathname = this.props.location.pathname.replace(/[^\w\s]/gi, "");
    request({
      url: "/site/pages/get",
      method: "POST",
      data: {
        slug: pathname,
      },
    }).then((res) => {
      if (res && +res.status === 1) {
        const { content } = res.response.result;

        this.setState({
          isLoader: false,
          content,
        });
      } else if (res && +res.status === 0) {
        this.setState({
          isLoader: false,
        });
      }
    });
  };

  render() {
    let texts = this.state.content;
    function createMarkup(text) {
      return { __html: text };
    }
    return (
      <>
        <HeaderOthers />
        <div className="termspage">
          <section className="tc-hero-section">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-3">
                  <div className="homepage">
                    <Link to="/">Home</Link>
                    <Link to="/termsandconditions">
                      <i className="fa fa-circle" aria-hidden="true"></i>
                      Terms and Conditions
                    </Link>
                  </div>
                </div>
                <div className="text-center col-md-6">
                  <div className="content-box">
                    <h2>Terms and Conditions</h2>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <div className="content-box">
                    <p dangerouslySetInnerHTML={createMarkup(texts)} />
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="img-box">
                    <img className="img-fluid" src={TermsImg} />
                  </div>
                </div>
              </div>
              {/* <div className="row">
              <div className="col-md-4 mt-3 mb-3">
                <div className="title">
                  <h5>Title</h5>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 mb-3">
                <div className="content-box">
                  <p>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor invidunt ut labore et dolore magna
                    aliquyam erat, sed diam voluptua. At vero eos et accusam et
                    justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                    sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                    ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                    nonumy eirmod tempor invidunt ut labore et dolore magna
                    aliquyam erat, sed diam voluptua. At vero eos et accusam et
                    justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                    sea takimata sanctus est Lorem ipsum dolor sit amet.
                  </p>
                </div>
              </div>
            </div> */}
            </div>
          </section>
        </div>
      </>
    );
  }
}

export default TermsAndConditions;
