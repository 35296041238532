import axios from "axios";

// export const NodeURL = "http://localhost:8000";

/* Staging URL CONFIG */

// export const NodeURL = "https://kingdom.devsdock.com";

/* Live URL CONFIG */

export const NodeURL = "https://www.kingdomgranite.co.za";

export const client = axios.create({
  baseURL: NodeURL,
});
client.defaults.responseType = "json";
const request = (options) => {
  const onSuccess = (response) => {
    return response.data; // This in turn returns the data from the server when status is 1
  };
  const onError = (error) => {
    if (error.response) {
      // Request was made but server responded with something other than 2xx
    } else {
      // Something else happened while setting up the request triggered the error
    }
    return Promise.reject(error.response || error.message);
  };
  return client(options).then(onSuccess).catch(onError);
};
export default request;
