import React, { Component } from "react";
import request, { NodeURL } from "../../../api/api";

import HeaderOthers from "../Common/HeaderOthers";

import "./allproducts.css";

import Select from "react-select";
import toast from "react-hot-toast";

/* React Select */

const colourStyles = {
  control: (styles, { isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: "white",
    cursor: "pointer",
    borderColor: isSelected ? "#968c8c" : "#cccccc",
    boxShadow: isSelected ? "#000000" : "",
    ":hover": {
      borderColor: "#000000",
      boxShadow: "#000000",
    },

    ":focus": {
      borderColor: "#000000",
    },
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      cursor: "pointer",
      backgroundColor: isFocused ? "#000000" : "white",
      color: isFocused ? "white" : "",
      ":hover": {
        backgroundColor: "#000000",
        color: "white",
      },
      "::placeholder": {
        fontSize: "14px",
      },
    };
  },
};
/* React Select */

class AllProducts extends Component {
  state = {
    maincategoryList: [],
    subcategoryList: [],
    innerTabs: "",
    activeTab: "1",
    isLawyerAppModal: false,
    toggle: false,

    mainCategoryListselect: [],
    mainCategorySelect: "",
    mainCategorySelectValue: "",

    subCategoryListSelect: [],
    subCategorySelect: "",
    subCategorySelectValue: "",

    coloursListselect: [],
    colours: "",
    coloursValue: "",
  };

  componentDidMount() {
    this.getMainCategoryAPI();
    this.getSubcategoryAPI();

    this.categoryList();
    this.coloursList();
    this.getProductsAPI();
  }

  categoryList = () => {
    request({
      url: "/site/product/maincategory/listall",
      method: "POST",
    }).then((res) => {
      if (res.status === 1) {
        let mainCategoryListselect = res.response.map((item) => ({
          value: item._id,
          label: item.name,
        }));

        this.setState({
          mainCategoryListselect,
        });
      } else if (res.status === 0) {
        // toast.error(res.response);
      }
    });
  };

  subCategoryListFunc = (value) => {
    request({
      url: "/site/product/subcategory/listall",
      method: "POST",
      data: {
        mainCategoryId: value,
      },
    }).then((res) => {
      if (res.status === 1) {
        let subCategoryListSelect = res.response.map((item) => ({
          value: item._id,
          label: item.name,
        }));

        this.setState({
          subCategoryListSelect,
        });
      } else if (res.status === 0) {
        // toast.error(res.response);
      }
    });
  };

  coloursList = () => {
    request({
      url: "/site/product/colours/listall",
      method: "POST",
    }).then((res) => {
      if (res.status === 1) {
        let coloursListselect = res.response.map((item) => ({
          value: item._id,
          label: item.name,
        }));

        this.setState({
          coloursListselect,
        });
      } else if (res.status === 0) {
        // toast.error(res.response);
      }
    });
  };

  getProductsAPI = () => {
    const {
      id,
      type,
      search,
      filter,
      mainCategoryFilter,
      subCategoryFilter,
      coloursFilter,
    } = this.state;
    request({
      url: "/site/list/all/products",
      method: "POST",
      data: {
        id,
        type,
        search: search,
        filter: filter,
        mainCategory: mainCategoryFilter,
        subCategory: subCategoryFilter,
        colours: coloursFilter,
      },
    }).then((res) => {
      if (res && +res.status === 1) {
        this.setState({
          isLoader: false,
          productList: res.response.result,
          pages: res.response.fullcount,
          currPage: res.response.length,
        });
      } else if (res && +res.status === 0) {
        this.setState({
          isLoader: false,
          productList: res.response.result,
          pages: res.response.fullcount,
          currPage: res.response.length,
        });
      }
    });
  };

  onSelectCategory = (value) => {
    if (value) {
      this.setState(
        {
          mainCategorySelect: value.value,
          mainCategorySelectValue: Array.of(value),
          subCategorySelect: "",
          subCategorySelectValue: "",
        },
        () => {
          this.subCategoryListFunc(value.value, "");
        }
      );
    } else {
      this.setState({
        mainCategorySelect: "",
        mainCategorySelectValue: "",
        subCategorySelect: "",
        subCategorySelectValue: "",
      });
      this.getProductsAPI();
    }
  };
  onSelectSubCategory = (valueType) => {
    if (valueType) {
      const value = valueType.value;
      const array = Array.of(valueType);

      this.setState({
        subCategorySelect: value,
        subCategorySelectValue: array,
      });
    } else {
      this.setState({
        subCategorySelect: "",
        subCategorySelectValue: "",
      });
      this.getProductsAPI();
    }
  };
  onSelectColours = (valueType) => {
    if (valueType) {
      const value = valueType.value;
      const array = Array.of(valueType);

      this.setState({
        colours: value,
        coloursValue: array,
      });
    } else {
      this.setState({
        colours: "",
        coloursValue: "",
      });
    }
  };

  isEven = (n) => {
    return n % 2 == 0;
  };

  goTo = (item, type, category) => {
    this.props.history.push({
      pathname: "/prodlist",
      state: {
        _id: item._id,
        type: type,
        category: category,
      },
    });
  };

  getMainCategoryAPI = () => {
    request({
      url: "/site/product/list/maincategory",
      method: "POST",
      // data: this.state.tableOptions,
    }).then((res) => {
      if (res && +res.status === 1) {
        this.setState({ maincategoryList: res.response.result }, () => {
          this.state.maincategoryList.map(
            (category) =>
              (document.getElementById(
                category._id
              ).style.backgroundImage = `url(${NodeURL + "/"}${
                category.pictures
              })`)
          );
        });

        this.getSubcategoryAPI();
      } else if (res && +res.status === 0) {
        this.setState({ subcategoryList: res.response.result });
      }
    });
  };
  getSubcategoryAPI = () => {
    request({
      url: "/site/product/list/subcategory",
      method: "POST",
      // data: this.state.tableOptions,
    }).then((res) => {
      if (res && +res.status === 1) {
        this.setState({ subcategoryList: res.response.result });
      } else if (res && +res.status === 0) {
        this.setState({ subcategoryList: res.response.result });
      }
    });
  };

  goToSelect = (mainCategory, subCategory, colours) => {
    if (mainCategory === "" && subCategory === "" && colours === "")
      return this.props.history.push({
        pathname: "/prodlist",
        state: {
          filter: false,
        },
      });

    this.props.history.push({
      pathname: "/prodlist",
      state: {
        filter: true,
        mainCategory: mainCategory,
        subCategory: subCategory,
        colours: colours,
      },
    });
  };

  render() {
    const {
      maincategoryList,
      subcategoryList,

      category,
      productList,
      mainCategorySelect,
      subCategorySelect,
      subCategory,
      mainCategoryListselect,
      mainCategorySelectValue,
      subCategoryListSelect,
      subCategorySelectValue,
      coloursListselect,
      coloursValue,
      colours,
      pictures,
      price,
      pricePer,
      suggestedProductList,
      description,
      subCategoryName,
    } = this.state;
    return (
      <>
        <HeaderOthers />
        <div className="page-wrapper all-products">
          <section className="hero-section-allproducts">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center">
                  <div className="head-content mb-3">
                    <h2 className="mb-5">Categories</h2>
                    <h6 className="mb-5">
                      Appearance is the first thing people take notice of.
                      Granite not only comes with incredible properties but
                      looks elegant as well.The colours along with unique
                      patterns make it the first choice of many. You can choose
                      the granite as per your specifications. The stone comes in
                      various colours and designs that are quite appealing.
                    </h6>
                  </div>
                </div>
              </div>
              <div className="head-dropdown pb-4 pt-4">
                <div className="row">
                  <div className="col-md-4">
                    <div>
                      <p>Color</p>
                      <div className="dropdown" id="colorSelect">
                        <Select
                          name="subCategory"
                          isClearable={true}
                          isSearchable={true}
                          options={coloursListselect}
                          cacheOptions
                          placeholder="Select Colors"
                          styles={colourStyles}
                          value={coloursValue}
                          onChange={(e) => this.onSelectColours(e)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div>
                      <p>Material</p>
                      <div className="dropdown" id="materialSelect">
                        <Select
                          name="mainCategory"
                          isClearable={true}
                          isSearchable={true}
                          options={mainCategoryListselect}
                          cacheOptions
                          placeholder="Select Material"
                          styles={colourStyles}
                          value={mainCategorySelectValue}
                          onChange={(e) => this.onSelectCategory(e)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div>
                      <p>Type</p>
                      <div className="dropdown" id="typeSelect">
                        <Select
                          name="subCategory"
                          isClearable={true}
                          isSearchable={true}
                          options={subCategoryListSelect}
                          cacheOptions
                          placeholder="Select Type"
                          styles={colourStyles}
                          value={subCategorySelectValue}
                          onChange={(e) => this.onSelectSubCategory(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row text-center">
                <div className="offset-md-4 col-md-4 mt-5">
                  <button
                    className="btn btn-light"
                    onClick={() =>
                      this.goToSelect(
                        mainCategorySelect,
                        subCategorySelect,
                        colours
                      )
                    }
                  >
                    View Products
                  </button>
                </div>
              </div>
            </div>
          </section>

          {maincategoryList.length > 0
            ? maincategoryList.map((item, i) => (
                <section className="category-section mt-5 mb-5">
                  <div className="container-fluid">
                    <div className="container">
                      <div className="row">
                        <div className="col-sm-3 col-md-4 mb-3">
                          <p className="section-head d-inline-block">
                            {i + 1} <span className="line"></span>
                          </p>
                          <p className="section-head d-inline-block ml-60">
                            {item.name}
                          </p>
                        </div>
                        <div className="col-sm-3 offset-md-4 col-md-4 mb-3">
                          <div className="bottom-btn text-right">
                            <button
                              className="btn btn-light"
                              onClick={() => this.goTo(item, "main", item.name)}
                            >
                              Explore All
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row align-items-center no-gutters">
                      <div className="col-md-6">
                        <div className="accordion" id="accordionExample">
                          {subcategoryList.length > 0
                            ? subcategoryList.map((sub) =>
                                item._id === sub.mainCategory ? (
                                  <div className="card">
                                    <div className="card-header">
                                      <h2 className="mb-0">
                                        <button
                                          className={`btn btn-link ${
                                            this.state.toggle &&
                                            this.state.id === sub._id
                                              ? ""
                                              : "collapsed"
                                          } `}
                                          type="button"
                                          aria-expanded="true"
                                          aria-controls="collapseOne"
                                          onClick={() =>
                                            this.setState({
                                              toggle: true,
                                              id: sub._id,
                                            })
                                          }
                                        >
                                          {sub.name}
                                        </button>
                                      </h2>
                                    </div>

                                    <div
                                      className={`collapse ${
                                        this.state.toggle &&
                                        this.state.id === sub._id
                                          ? "show"
                                          : ""
                                      } `}
                                      aria-labelledby={item._id}
                                      data-parent="#accordionExample"
                                    >
                                      <div className="card-body">
                                        <p>{sub.description}</p>
                                        <a
                                          className="underline"
                                          onClick={() =>
                                            this.goTo(
                                              sub,
                                              "sub",
                                              sub.mainCategoryName
                                            )
                                          }
                                        >
                                          View All{" "}
                                          <i
                                            className="fa fa-angle-right"
                                            aria-hidden="true"
                                          ></i>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                ) : null
                              )
                            : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div
                          className="img-wrapper allproducts-img1"
                          id={item._id}
                        ></div>
                      </div>
                    </div>
                  </div>
                </section>
              ))
            : null}
        </div>
      </>
    );
  }
}

export default AllProducts;
