import React, { Component } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Button,
} from "reactstrap";

import { Link } from "react-router-dom";

import classNames from "classnames";

import toast, { ToastBar, Toaster } from "react-hot-toast";

import "./modaluser.css";

import PopupLogo from "../../../img/logo_dark.png";
import SuccessIcon from "../../../img/992704@3x.png";
import request from "../../../api/api";
import jwtDecode from "jwt-decode";

export default class ModalUser extends Component {
  state = {
    innerTabs: "",
    userData: "",
    activeTab: "1",
    type: "guest",
    isLawyerAppModal: this.props.isLawyerAppModal,
    otpVerified: false,
    fullName: "",
    modalState: "",
    email: "",
    phone: {
      number: "",
      code: "",
      dialcountry: "",
    },
    password: "",
    new_password: "",
    confirmPassword: "",
    otp1: "",
    otp2: "",
    otp3: "",
    otp4: "",
  };

  componentDidMount() {}

  checkUser = () => {
    const getData = localStorage.getItem("KGU");

    if (getData) {
      const token = jwtDecode(getData);

      if (token) {
        this.setState(
          {
            userData: token,
          },
          () => {
            this.props.quoteSubmitAPI("user", token, "user");
          }
        );
      }
    }
  };

  onChangeObject = (e, stateValue) => {
    const name = e.target.name;
    const value = e.target.value;
    stateValue[name] = value;
    this.setState({
      stateValue,
    });
  };
  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  onChangeOTP = (e, type) => {
    const re = /^[0-9\b]+$/;

    if (e.target.value === "" || re.test(e.target.value)) {
      // Allow only numbers in textbox
      var max_chars = 1;
      var num = String(e.target.value);
      this.setState(
        {
          [e.target.name]: num,
        },
        () => {
          if (type === "otpend") return;
          if (num.length === max_chars) {
            // Focus next input

            return document.getElementById(type).focus();
          }
        }
      );
    }
  };
  onCheckedChange = (e) => {
    this.setState({
      [e.target.name]: e.target.checked,
    });
  };

  goTo = (value) => {
    this.setState({
      activeTab: value,
    });
  };

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  checkEquality = (password, confirmPassword) => {
    return String(password) === String(confirmPassword);
  };

  onChangeModalState = (type) => {
    this.setState({
      modalState: type,
    });
    if (type === "") {
      this.goTo("1");
    }
  };

  onLogin = (e) => {
    e.preventDefault();
    const { email, password } = this.state;
    // const toastId = toast.loading("Sigining in......");

    if (email === "")
      return toast.error("Email required.", {
        id: "email",
      });
    if (password === "")
      return toast.error("Password required.", {
        id: "email",
      });
    if (password.length <= 3)
      return toast.error("Invalid password, minimum length: 4", {
        id: "email",
      });

    request({
      url: "/login",
      method: "POST",
      data: {
        email,
        password,
      },
    }).then((res) => {
      if (res.status === 1) {
        localStorage.setItem("KGU", res.response.authToken);
        localStorage.setItem("KGUT", true);

        if (this.props.isQuote) {
          this.checkUser();
          return;
        }
        this.props.history.push({
          pathname: "/",
          state: { toastMessage: "Logged in" },
        });

        setTimeout(() => {
          window.location.reload();
          // toast.dismiss(toastId);
        }, 1000);

        this.componentDidMount();
        this.props.onOpenLawyerAppModal();
      } else {
        toast.error(res.response, {
          id: "email",
        });
        // toast.dismiss(toastId);
      }
    });
  };

  OnForgotPassword = (e) => {
    if (this.state.email === "")
      return toast.error("Email required.", {
        id: "email",
      });
    request({
      url: "/forgotpassword",
      method: "POST",
      data: {
        email: this.state.email,
        windows_url: `${window.location.origin}/`,
      },
    }).then((res) => {
      if (res.status === 1) {
        this.onChangeModalState("otp");
        toast.success("OTP sent", {
          id: "email",
        });
      } else if (res.status === 0) {
        toast.error(res.message, {
          id: "email",
        });
      }
    });
  };

  onVerifyOTP = (e) => {
    e.preventDefault();
    const { email, otp1, otp2, otp3, otp4 } = this.state;
    if (otp1 === "")
      return toast.error("OTP required", {
        id: "email",
      });
    request({
      url: "/verify/otp",
      method: "POST",
      data: {
        email: email,
        otp: otp1 + otp2 + otp3 + otp4,
      },
    }).then((res) => {
      if (res.status === 1) {
        this.setState({
          otpVerified: true,
        });
        toast.success("Success", {
          id: "email",
        });
        setTimeout(() => {
          this.onChangeModalState("change_password");
        }, 2000);
      } else if (res.status === 0) {
        return toast.error(res.response, {
          id: "otp",
        });
      }
    });
  };

  onChangePassword = (e) => {
    const { email, password, new_password } = this.state;
    if (password === "" || new_password === "")
      return toast.success("password is required", {
        id: "password",
      });
    request({
      url: "/site/change_password",
      method: "POST",
      data: {
        email: email,
        password: password,
        new_password: new_password,
      },
    }).then((res) => {
      if (res.status === 1) {
        this.onChangeModalState("change_password_success");
        this.setState({
          password: "",
        });
      } else if (res.status === 0) {
        return toast.error(res.response, {
          id: "change",
        });
      }
    });
  };

  onRegister = (e) => {
    e.preventDefault();

    const { fullName, email, password, confirmPassword, phone } = this.state;

    if (fullName === "") {
      toast.error("Fullname required.", {
        id: "fullName",
      });

      return;
    }

    if (email === "") {
      toast.error("Email required.", {
        id: "email",
      });
      return;
    }

    if (password === "") {
      toast.error("Password required.", {
        id: "password",
      });
      return;
    }
    if (confirmPassword === "") {
      toast.error("Password required.", {
        id: "confirmPassword",
      });
      return;
    }

    if (!this.checkEquality(password, confirmPassword)) {
      toast.error("Passwords do not match.", {
        id: "fullName",
      });
      return;
    }

    request({
      url: "/register",
      method: "POST",
      data: {
        fullName,
        phone,
        email,
        password,
        confirmPassword,
      },
    }).then((res) => {
      if (res.status === 1) {
        toast.success("Registered !");
        this.goTo("1");
      } else if (res.status === 0) {
        toast.error("Failed Error");
      }
    });
  };

  render() {
    const {
      activeTab,
      fullName,
      email,
      password,
      new_password,
      confirmPassword,
      phone,
      remember,
      isLawyerAppModal,
      modalState,
      otpVerified,
      otp1,
      otp2,
      otp3,
      otp4,
    } = this.state;
    return (
      <Modal
        className="signuppage"
        isOpen={isLawyerAppModal}
        toggle={this.props.onOpenLawyerAppModal}
      >
        <ModalHeader
          className="head-bg"
          toggle={this.props.onOpenLawyerAppModal}
        >
          <div className="container">
            <div className="row">
              <div className="signin-logo mx-auto text-center">
                <img className="img-fluid mx-auto d-block" src={PopupLogo} />
              </div>
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <>
            <Toaster
              position="bottom-center"
              reverseOrder={false}
              containerClassName=""
              containerStyle={{}}
              toastOptions={{
                // Define default options

                // Default options for specific types
                loading: {
                  duration: 3000,
                  theme: {
                    primary: "green",
                    secondary: "black",
                  },
                  style: {
                    background: "#363636",
                    color: "#fff",
                    width: "100%",
                  },
                },
                success: {
                  duration: 3000,
                  theme: {
                    primary: "green",
                    secondary: "black",
                  },
                  style: {
                    background: "#363636",
                    color: "#fff",
                    width: "100%",
                  },
                },
                error: {
                  duration: 3000,
                  theme: {
                    primary: "green",
                    secondary: "black",
                  },
                  style: {
                    background: "#363636",
                    color: "#fff",
                    width: "100%",
                  },
                },
              }}
            />

            {modalState === "" ? (
              <div className="container">
                <div className="row bdr-btm">
                  <Nav tabs>
                    <div className="col-md-6 p-0 m-0">
                      <NavItem>
                        <NavLink
                          className={classNames({
                            active: activeTab === "1",
                          })}
                          onClick={() => {
                            this.toggle("1");
                          }}
                        >
                          Sign In
                        </NavLink>
                      </NavItem>
                    </div>
                    <div className="col-md-6 p-0 m-0">
                      <NavItem>
                        <NavLink
                          className={classNames({
                            active: activeTab === "2",
                          })}
                          onClick={() => {
                            this.toggle("2");
                          }}
                        >
                          Sign Up
                        </NavLink>
                      </NavItem>
                    </div>
                  </Nav>
                </div>

                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <form
                      className="container signin-page-popup p-5"
                      onSubmit={this.onLogin}
                    >
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-box mb-4">
                            <input
                              type="email"
                              className="form-control"
                              aria-describedby="emailHelp"
                              placeholder="Enter Email"
                              name="email"
                              value={email}
                              onChange={this.onChange}
                            />
                            <i
                              className="fa fa-envelope"
                              aria-hidden="true"
                            ></i>
                          </div>
                        </div>
                        <div className="col-md-12 mb-4">
                          <div className="form-box">
                            <input
                              type="password"
                              className="form-control"
                              name="password"
                              value={password}
                              onChange={this.onChange}
                              placeholder="Enter Password"
                            />
                            <i className="fa fa-lock" aria-hidden="true"></i>
                          </div>
                        </div>
                        <div className="col-md-6 mt-2">
                          <div className="form-checkbox">
                            <input
                              type="checkbox"
                              id="vehicle1"
                              name="remember"
                              value={remember}
                              onChange={this.onCheckedChange}
                            />
                            <label className="ml-2 rememberme" for="rememberme">
                              {" "}
                              Remember Me
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="frg-pwd text-right">
                            <Link
                              onClick={() => this.onChangeModalState("forgot")}
                              className="nav-link"
                            >
                              Forgot Password?
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="submit-btn text-center mt-4">
                        <Button className="btn btn-light" type="submit">
                          Sign in
                        </Button>
                      </div>
                      <div className="notmember text-center mt-2">
                        <p href="">Not a member?</p>{" "}
                        <Link onClick={() => this.goTo("2")}>Signup</Link>
                      </div>
                    </form>
                  </TabPane>

                  <TabPane tabId="2">
                    <form
                      className="container signup-page-popup p-5"
                      onSubmit={this.onRegister}
                    >
                      <div className="row">
                        <div className="col-md-12 mb-4">
                          <div className="form-box">
                            <input
                              className="form-control"
                              id="name"
                              type="text"
                              name="fullName"
                              value={fullName}
                              onChange={this.onChange}
                              placeholder="Enter Full Name"
                            />
                            <i className="fa fa-user" aria-hidden="true"></i>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-box mb-4">
                            <input
                              type="email"
                              className="form-control"
                              id="exampleInputEmail1"
                              aria-describedby="emailHelp"
                              placeholder="Enter Email"
                              name="email"
                              value={email}
                              onChange={this.onChange}
                            />
                            <i
                              className="fa fa-envelope"
                              aria-hidden="true"
                            ></i>
                          </div>
                        </div>
                        <div className="col-md-12 mb-4">
                          <div className="form-box">
                            <input
                              className="form-control"
                              id="phone"
                              placeholder="Mobile Number"
                              name="number"
                              type="input"
                              maxlength="10"
                              value={phone.number}
                              onChange={(e) => this.onChangeObject(e, phone)}
                            />
                            <i className="fa fa-mobile" aria-hidden="true"></i>
                          </div>
                        </div>
                        <div className="col-md-12 mb-4">
                          <div className="form-box">
                            <input
                              type="password"
                              className="form-control"
                              name="password"
                              value={password}
                              onChange={this.onChange}
                              placeholder="Password"
                            />
                            <i className="fa fa-lock" aria-hidden="true"></i>
                          </div>
                        </div>
                        <div className="col-md-12 mb-4">
                          <div className="form-box">
                            <input
                              type="password"
                              className="form-control"
                              name="confirmPassword"
                              placeholder="Confirm Passowrd"
                              value={confirmPassword}
                              onChange={this.onChange}
                            />
                            <i className="fa fa-lock" aria-hidden="true"></i>
                          </div>
                        </div>
                        <p className="confirmationmsg text-center">
                          By clicking signup you will accepting to our terms &
                          conditions and privacy policy
                        </p>
                      </div>
                      <div className="submit-btn text-center mt-4">
                        <Button
                          className="btn btn-light"
                          onClick={this.onRegister}
                          type="submit"
                        >
                          Signup
                        </Button>
                      </div>
                      <div className="notmember text-center mt-2">
                        <p href="">
                          Already a member?{" "}
                          <Link onClick={() => this.goTo("1")}>Signin</Link>
                        </p>
                      </div>
                    </form>
                  </TabPane>
                </TabContent>
              </div>
            ) : null}

            {/* create new password start */}

            {modalState === "forgot" ? (
              <>
                <div className="container p-5">
                  <h6 className="text-center mb-3">Forgot Password</h6>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-box mb-4">
                        <input
                          type="email"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Enter Email"
                          name="email"
                          value={email}
                          onChange={this.onChange}
                        />
                        <i className="fa fa-envelope" aria-hidden="true"></i>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="submit-btn text-center mb-3">
                  <Button
                    className="btn btn-light"
                    onClick={this.OnForgotPassword}
                  >
                    Next
                  </Button>
                </div>
                <div className="notmember text-center mt-2 mb-5">
                  <p href="">
                    Just Remembered?{" "}
                    <Link onClick={() => this.onChangeModalState("")}>
                      Signin
                    </Link>
                  </p>
                </div>
              </>
            ) : null}
            {modalState === "otp" ? (
              <form onSubmit={this.onVerifyOTP}>
                <div className="container p-5">
                  <h6 className="text-center mb-3">Forgot Password</h6>

                  <p className="confirmationmsg text-center">
                    An OTP will be sent to registered email address
                  </p>
                  <h6 className="text-center mt-3">Enter OTP</h6>
                  <div className="align-items-center text-center mt-3">
                    <input
                      type="text"
                      className="form-control otp d-inline-block"
                      autofocus=""
                      name="otp1"
                      value={otp1}
                      maxlength="1"
                      onChange={(e) => this.onChangeOTP(e, "otp2")}
                    />
                    <input
                      type="text"
                      className="form-control otp d-inline-block"
                      name="otp2"
                      id="otp2"
                      value={otp2}
                      maxlength="1"
                      onChange={(e) => this.onChangeOTP(e, "otp3")}
                    />
                    <input
                      type="text"
                      className="form-control otp d-inline-block"
                      name="otp3"
                      id="otp3"
                      value={otp3}
                      maxlength="1"
                      onChange={(e) => this.onChangeOTP(e, "otp4")}
                    />
                    <input
                      type="text"
                      className="form-control otp d-inline-block"
                      name="otp4"
                      id="otp4"
                      value={otp4}
                      maxlength="1"
                      onChange={(e) => this.onChangeOTP(e, "otpend")}
                    />
                  </div>
                  <Link
                    className="float-right resendotp"
                    onClick={this.onVerifyOTP}
                  >
                    Resend OTP
                  </Link>
                </div>
                {otpVerified ? (
                  <div className="auth text-center mb-2">
                    <p className="valid">Verified</p>
                  </div>
                ) : null}
                <div className="submit-btn text-center mb-3">
                  <Button className="btn btn-light" type="submit">
                    Next
                  </Button>
                </div>
              </form>
            ) : null}

            {modalState === "change_password" ? (
              <div className="container newpassword p-5">
                <div className="head-content text-center mb-3">
                  <h6>Create New Password</h6>
                </div>
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <div className="form-box">
                      <input
                        type="password"
                        className="form-control"
                        id="pwd"
                        name="new_password"
                        value={new_password}
                        onChange={this.onChange}
                        placeholder="New Passowrd"
                      />
                      <i className="fa fa-lock" aria-hidden="true"></i>
                    </div>
                  </div>
                  <div className="col-md-12 mb-5">
                    <div className="form-box">
                      <input
                        type="password"
                        className="form-control"
                        id="pwd"
                        name="password"
                        value={password}
                        onChange={this.onChange}
                        placeholder="Confirm Passowrd"
                      />
                      <i className="fa fa-lock" aria-hidden="true"></i>
                    </div>
                  </div>
                </div>
                <div className="submit-btn text-center mt-4">
                  <Link
                    className="btn btn-light"
                    onClick={this.onChangePassword}
                  >
                    Submit
                  </Link>
                </div>
              </div>
            ) : null}

            {modalState === "change_password_success" ? (
              <div className="container p-5">
                <div className="text-center mb-5">
                  <img
                    className="img-fluid successicon mb-3"
                    src={SuccessIcon}
                  />
                  <h6 className="mb-5">Password Changed Successfully</h6>
                </div>
                <div className="submit-btn text-center mt-5 mb-3">
                  <Link
                    className="btn btn-light mt-5"
                    onClick={() => this.onChangeModalState("")}
                  >
                    Login
                  </Link>
                </div>
              </div>
            ) : null}
          </>
        </ModalBody>
      </Modal>
    );
  }
}
