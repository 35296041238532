import React, { Component } from "react";
import { Link } from "react-router-dom";

import HeaderOthers from "../Common/HeaderOthers";

import StarryRed from "../../../img/Image15@3x.png";

import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";

import { Modal, ModalHeader, ModalBody } from "reactstrap";
import "./allproductslist.css";
import request, { NodeURL } from "../../../api/api";
import Select from "react-select";
import toast, { Toaster } from "react-hot-toast";

import SwiperSlider from "./SwiperSlider";

/* React Select */

const colourStyles = {
  control: (styles, { isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: "white",
    cursor: "pointer",
    borderColor: isSelected ? "#968c8c" : "#cccccc",
    boxShadow: isSelected ? "#000000" : "",
    ":hover": {
      borderColor: "#000000",
      boxShadow: "#000000",
    },

    ":focus": {
      borderColor: "#000000",
    },
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      cursor: "pointer",
      backgroundColor: isFocused ? "#000000" : "white",
      color: isFocused ? "white" : "",
      ":hover": {
        backgroundColor: "#000000",
        color: "white",
      },
    };
  },
  placeholder: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      fontSize: "14px",
    };
  },
};
/* React Select */

class AllProductsList extends Component {
  state = {
    id:
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state._id,
    type:
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.type,
    category:
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.category,
    categoryDescription:
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.categoryDescription,
    filter:
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.filter,
    mainCategoryFilter:
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.mainCategory,
    subCategoryFilter:
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.subCategory,
    coloursFilter:
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.colours,
    productList: [],
    suggestedProductList: [],
    innerTabs: "",
    slideSwiper: null,
    activeTab: "1",
    isLawyerAppModal: false,
    search: "",
    mainCategory: "",
    mainCategoryName: "",
    name: "",
    pictures: [],
    price: "",
    pricePer: "",
    status: "",
    subCategory: "",
    subCategoryName: "",

    mainCategoryListselect: [],
    mainCategorySelect: "",
    mainCategorySelectValue: "",

    subCategoryListSelect: [],
    subCategorySelect: "",
    subCategorySelectValue: "",

    coloursListselect: [],
    colours: "",
    coloursValue: "",
  };

  componentDidMount() {
    window.scroll(0, 0);
    this.getProductsAPI();
    this.categoryList();
    this.coloursList();
  }
  onPageQuote = (mainCategory, subCategory, product_id, color) => {
    return this.props.history.push({
      pathname: "/quotes",
      state: {
        main: mainCategory,
        sub: subCategory,
        color: color,
        product_id: product_id,
        service: "",
      },
    });
  };

  search(value) {
    this.setState(
      (state) => {
        state.search = value;
      },
      () => {
        this.getProductsAPI();
      }
    );
  }

  filterSelect = () => {
    const { mainCategorySelect, subCategorySelect, colours } = this.state;
    if (mainCategorySelect === "")
      return toast.error("Select material.", {
        id: "filter",
      });
    if (subCategorySelect === "")
      return toast.error("Select type.", {
        id: "filter",
      });

    this.getFilterdProductsAPI(mainCategorySelect, subCategorySelect, colours);
  };
  getFilterdProductsAPI = (mainCategorySelect, subCategorySelect, colours) => {
    const { id, type } = this.state;
    request({
      url: "/site/list/filter/products",
      method: "POST",
      data: {
        id,
        type,
        mainCategory: mainCategorySelect,
        subCategory: subCategorySelect,
        colours: colours,
      },
    }).then((res) => {
      if (res && +res.status === 1) {
        this.setState({
          isLoader: false,
          productList: res.response.result,
          pages: res.response.fullcount,
          currPage: res.response.length,
        });
      } else if (res && +res.status === 0) {
        this.setState({
          isLoader: false,
          productList: res.response.result,
          pages: res.response.fullcount,
          currPage: res.response.length,
        });
      }
    });
  };
  getProductsAPI = () => {
    const {
      id,
      type,
      search,
      filter,
      mainCategoryFilter,
      subCategoryFilter,
      coloursFilter,
    } = this.state;
    request({
      url: "/site/list/all/products",
      method: "POST",
      data: {
        id,
        type,
        search: search,
        filter: filter,
        mainCategory: mainCategoryFilter,
        subCategory: subCategoryFilter,
        colours: coloursFilter,
      },
    }).then((res) => {
      if (res && +res.status === 1) {
        this.setState({
          isLoader: false,
          productList: res.response.result,
          pages: res.response.fullcount,
          currPage: res.response.length,
        });
      } else if (res && +res.status === 0) {
        this.setState({
          isLoader: false,
          productList: res.response.result,
          pages: res.response.fullcount,
          currPage: res.response.length,
        });
      }
    });
  };
  getFullProductsAPI = () => {
    request({
      url: "/site/list/all/products",
      method: "POST",
    }).then((res) => {
      if (res && +res.status === 1) {
        this.setState({
          isLoader: false,
          productList: res.response.result,
          pages: res.response.fullcount,
          currPage: res.response.length,
        });
      } else if (res && +res.status === 0) {
        this.setState({
          isLoader: false,
          productList: res.response.result,
          pages: res.response.fullcount,
          currPage: res.response.length,
        });
      }
    });
  };

  onSelectCategory = (value) => {
    if (value) {
      this.setState(
        {
          mainCategorySelect: value.value,
          mainCategorySelectValue: Array.of(value),
          subCategorySelect: "",
          subCategorySelectValue: "",
        },
        () => {
          this.subCategoryListFunc(value.value, "");
          this.getFilterdProductsAPI(
            value.value,
            undefined,
            this.state.colours
          );
        }
      );
    } else {
      this.setState({
        mainCategorySelect: "",
        mainCategorySelectValue: "",
        subCategorySelect: "",
        subCategorySelectValue: "",
      });
      this.getFilterdProductsAPI(undefined, undefined, this.state.colours);
    }
  };
  onSelectSubCategory = (valueType) => {
    if (valueType) {
      const value = valueType.value;
      const array = Array.of(valueType);

      this.setState({
        subCategorySelect: value,
        subCategorySelectValue: array,
      });
      this.getFilterdProductsAPI(
        this.state.mainCategorySelect,
        value,
        this.state.colours
      );
    } else {
      this.setState({
        subCategorySelect: "",
        subCategorySelectValue: "",
      });
      this.getFilterdProductsAPI(
        this.state.mainCategorySelect,
        undefined,
        this.state.colours
      );
    }
  };
  onSelectColours = (valueType) => {
    const { filter } = this.state;

    if (valueType) {
      const value = valueType.value;
      const array = Array.of(valueType);

      this.setState({
        colours: value,
        coloursValue: array,
      });
      this.getFilterdProductsAPI(
        this.state.mainCategorySelect,
        this.state.subCategorySelect,
        value
      );
    } else {
      this.setState({
        colours: "",
        coloursValue: "",
      });

      if (filter === undefined || filter === false)
        return this.getProductsAPI();

      this.getFullProductsAPI();
    }
  };

  categoryList = () => {
    request({
      url: "/site/product/maincategory/listall",
      method: "POST",
    }).then((res) => {
      if (res.status === 1) {
        let mainCategoryListselect = res.response.map((item) => ({
          value: item._id,
          label: item.name,
        }));

        this.setState(
          {
            mainCategoryListselect,
          },
          () => {
            if (this.state.mainCategoryFilter) {
              this.setState({
                mainCategorySelect: this.state.mainCategoryFilter,
                mainCategorySelectValue: mainCategoryListselect.filter(
                  (item) => item.value === this.state.mainCategoryFilter
                ),
              });

              this.subCategoryListFunc(this.state.mainCategoryFilter);
            }
          }
        );
      } else if (res.status === 0) {
        // toast.error(res.response);
      }
    });
  };

  subCategoryListFunc = (value) => {
    request({
      url: "/site/product/subcategory/listall",
      method: "POST",
      data: {
        mainCategoryId: value,
      },
    }).then((res) => {
      if (res.status === 1) {
        let subCategoryListSelect = res.response.map((item) => ({
          value: item._id,
          label: item.name,
        }));

        this.setState(
          {
            subCategoryListSelect,
          },
          () => {
            if (this.state.subCategoryFilter) {
              this.setState({
                subCategorySelect: this.state.subCategoryFilter,
                subCategorySelectValue: subCategoryListSelect.filter(
                  (item) => item.value === this.state.subCategoryFilter
                ),
              });
            }
          }
        );
      } else if (res.status === 0) {
        // toast.error(res.response);
      }
    });
  };

  coloursList = () => {
    request({
      url: "/site/product/colours/listall",
      method: "POST",
    }).then((res) => {
      if (res.status === 1) {
        let coloursListselect = res.response.map((item) => ({
          value: item._id,
          label: item.name,
        }));

        this.setState(
          {
            coloursListselect,
          },
          () => {
            if (this.state.coloursFilter) {
              this.setState({
                colours: this.state.coloursFilter,
                coloursValue: coloursListselect.filter(
                  (item) => item.value === this.state.coloursFilter
                ),
              });
            }
          }
        );
      } else if (res.status === 0) {
        // toast.error(res.response);
      }
    });
  };

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }
  onOpenLawyerAppModal = (item) => {
    this.setState({
      isLawyerAppModal: !this.state.isLawyerAppModal,
      mainCategory: item.mainCategory,
      mainCategoryName: item.mainCategoryName,
      name: item.name,
      product_id: item._id,
      colour: item.colour,
      description: item.description,
      pictures: item.pictures,
      price: item.price,
      pricePer: item.pricePer,
      status: item.status,
      subCategory: item.subCategory,
      subCategoryName: item.subCategoryName,
    });
  };
  onsubmitSuggested = (item) => {
    this.setState(
      {
        mainCategory: item.mainCategory,
        mainCategoryName: item.mainCategoryName,
        name: item.name,
        description: item.description,
        pictures: item.pictures,
        price: item.price,
        pricePer: item.pricePer,
        status: item.status,
        subCategory: item.subCategory,
        subCategoryName: item.subCategoryName,
      },
      () => {
        this.getProductsSuggestionAPI(item.subCategory, item.name);
      }
    );
  };

  onCloseLawyerAppModal = () => {
    this.setState({
      isLawyerAppModal: false,
      mainCategory: "",
      mainCategoryName: "",
      name: "",
      description: "",
      pictures: [],
      suggestedProductList: [],
      price: "",
      pricePer: "",
      status: "",
      subCategory: "",
      subCategoryName: "",
    });
  };

  render() {
    const {
      category,
      categoryDescription,
      productList,
      isLawyerAppModal,
      mainCategory,
      subCategory,
      mainCategoryListselect,
      mainCategorySelectValue,
      subCategoryListSelect,
      subCategorySelectValue,
      coloursListselect,
      coloursValue,
      name,
      product_id,
      colour,
      pictures,
      price,
      pricePer,
      suggestedProductList,
      description,
      subCategoryName,
    } = this.state;
    return (
      <>
        <HeaderOthers />
        <div class="page-wrapper allproductslist">
          <Toaster
            position="bottom-center"
            reverseOrder={false}
            containerClassName=""
            containerStyle={{}}
            toastOptions={{
              // Define default options

              // Default options for specific types
              duration: 3000,
              style: {
                background: "#363636",
                color: "#fff",
                width: "100%",
              },
            }}
          />
          <section className="hero-section-allProductsList">
            <div class="container">
              <div className="row align-items-center pb-5">
                <div className="col-md-2">
                  <div className="homepage">
                    <Link to="/">Home</Link>
                    <Link to="/prodlist">
                      <i className="fa fa-circle" aria-hidden="true"></i>
                      All Products
                    </Link>
                  </div>
                </div>
                <div className="text-center col-md-8">
                  <div className="content-box">
                    <h2>
                      {category === undefined ? "All Products" : category}
                    </h2>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="search-box-expand">
                    <form id="search-box-expand">
                      <input
                        type="search"
                        placeholder="Search"
                        onChange={(e) => this.search(e.target.value)}
                      />
                    </form>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 text-center">
                  <div class="head-content mb-3">
                    <h6>
                      {categoryDescription === undefined
                        ? null
                        : categoryDescription}
                    </h6>
                  </div>
                </div>
              </div>
              <div className="head-dropdown pb-4 pt-4">
                <div className="row">
                  <div className="col-md-4">
                    <div>
                      <p>Color</p>
                      <div className="dropdown">
                        <Select
                          name="subCategory"
                          isClearable={true}
                          isSearchable={true}
                          options={coloursListselect}
                          cacheOptions
                          placeholder="Select Color"
                          styles={colourStyles}
                          value={coloursValue}
                          onChange={(e) => this.onSelectColours(e)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div>
                      <p>Material</p>
                      <div className="dropdown">
                        <Select
                          className="dropdown-item-list"
                          name="mainCategory"
                          isClearable={true}
                          isSearchable={true}
                          options={mainCategoryListselect}
                          cacheOptions
                          placeholder="Select Material"
                          styles={colourStyles}
                          value={mainCategorySelectValue}
                          onChange={(e) => this.onSelectCategory(e)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div>
                      <p>Type</p>
                      <div className="dropdown">
                        <Select
                          name="subCategory"
                          isClearable={true}
                          isSearchable={true}
                          options={subCategoryListSelect}
                          cacheOptions
                          placeholder="Select Type"
                          styles={colourStyles}
                          value={subCategorySelectValue}
                          onChange={(e) => this.onSelectSubCategory(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div class="row text-center">
              <div class="offset-md-4 col-md-4 mt-5">
                <button class="btn btn-secondary" onClick={this.filterSelect}>
                  Enter
                </button>
              </div>
            </div> */}
            </div>
          </section>
          <section class="products-section">
            <div class="container">
              <div class="row">
                {productList.length > 0 ? (
                  productList.map((item, i) => (
                    <div
                      class="col-md-6 col-lg-3"
                      key={i}
                      onClick={() => this.onOpenLawyerAppModal(item)}
                    >
                      <div class="product-box">
                        <div class="product-box-img text-center">
                          <img
                            class="img-fluid"
                            src={
                              item.pictures[0].path !== ""
                                ? NodeURL + "/" + item.pictures[0].path
                                : StarryRed
                            }
                          />
                        </div>
                        <div class="product-box-content mt-4">
                          <h5>{item.name}</h5>
                          <p class="first-content">{item.mainCategoryName}</p>
                          <p class="second-content">Starts at</p>
                          <p class="third-content">
                            R{item.price} {item.pricePer}
                          </p>
                          <div class="content-detail-inner">
                            <div class="text-left inner-link">
                              <a
                                class="underline"
                                onClick={() => this.onOpenLawyerAppModal(item)}
                              >
                                More Details{" "}
                                <i
                                  class="fa fa-angle-right"
                                  aria-hidden="true"
                                ></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <td colSpan={9}>
                    <h5>No records available</h5>
                  </td>
                )}
              </div>
            </div>
          </section>
          <Modal
            className="modalpopup"
            isOpen={isLawyerAppModal}
            toggle={this.onCloseLawyerAppModal}
          >
            <ModalHeader toggle={this.onCloseLawyerAppModal}></ModalHeader>
            <ModalBody>
              {pictures.length > 0 ? (
                <SwiperSlider
                  pictures={pictures}
                  name={name}
                  subCategoryName={subCategoryName}
                  description={description}
                  price={price}
                  pricePer={pricePer}
                  mainCategory={mainCategory}
                  subCategory={subCategory}
                  product_id={product_id}
                  colour={colour}
                  onPageQuote={this.onPageQuote}
                />
              ) : null}
            </ModalBody>
          </Modal>
        </div>
      </>
    );
  }
}

export default AllProductsList;
