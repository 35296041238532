export const capetownSuburbs = [
  {
    surbub: "Atlantis",
    postal_code: "8005",
  },
  {
    surbub: "Bantry Bay",
    postal_code: "8005",
  },
  {
    surbub: "Belhar",
    postal_code: "8005",
  },
  {
    surbub: "Bellville",
    postal_code: "8005",
  },
  {
    surbub: "Bergvliet",
    postal_code: "7806",
  },
  {
    surbub: "Bishop Lavis",
    postal_code: "7806",
  },
  {
    surbub: "Bishopscourt",
    postal_code: "7806",
  },
  {
    surbub: "Bloubergstrand",
    postal_code: "8005",
  },
  {
    surbub: "Bo-Kaap (Malay Quarter)",
    postal_code: "8005",
  },
  {
    surbub: "Bonteheuwel",
    postal_code: "7490",
  },
  {
    surbub: "Bothasig",
    postal_code: "7764",
  },
  {
    surbub: "Brackenfell",
    postal_code: "7780",
  },
  {
    surbub: "Brooklyn",
    postal_code: "7755",
  },
  {
    surbub: "Camps Bay",
    postal_code: "7460",
  },
  {
    surbub: "Camps Bay",
    postal_code: "7750",
  },
  {
    surbub: "Cape Peninsula",
    postal_code: "8005",
  },
  {
    surbub: "Capri Village",
    postal_code: "7780",
  },
  {
    surbub: "Capri Village",
    postal_code: "7490",
  },
  {
    surbub: "Claremont",
    postal_code: "7784",
  },
  {
    surbub: "Clifton",
    postal_code: "7455",
  },
  {
    surbub: "Clifton",
    postal_code: "7945",
  },
  {
    surbub: "Clovelly",
    postal_code: "7785",
  },
  {
    surbub: "Constantia",
    postal_code: "7750",
  },
  {
    surbub: "Crawford",
    postal_code: "7800",
  },
  {
    surbub: "Crossroads",
    postal_code: "",
  },
  {
    surbub: "Darling",
    postal_code: "7750",
  },
  {
    surbub: "De Waterkant",
    postal_code: "7785",
  },
  {
    surbub: "De Waterkant",
    postal_code: "7798",
  },
  {
    surbub: "Delft",
    postal_code: "7490",
  },
  {
    surbub: "Devil's Peak Estate",
    postal_code: "7945",
  },
  {
    surbub: "Devil's Peak Estate",
    postal_code: "8001",
  },
  {
    surbub: "Diep River",
    postal_code: "8001",
  },
  {
    surbub: "Durbanville",
    postal_code: "8001",
  },
  {
    surbub: "Edgemead",
    postal_code: "8001",
  },
  {
    surbub: "Eersteriver",
    postal_code: "8001",
  },
  {
    surbub: "Elsie's River",
    postal_code: "8001",
  },
  {
    surbub: "Epping",
    postal_code: "8001",
  },
  {
    surbub: "Firgrove",
    postal_code: "8001",
  },
  {
    surbub: "Fish Hoek",
    postal_code: "7925",
  },
  {
    surbub: "Fish Hoek",
    postal_code: "8001",
  },
  {
    surbub: "Foreshore",
    postal_code: "8001",
  },
  {
    surbub: "Foreshore",
    postal_code: "8001",
  },
  {
    surbub: "Fresnaye",
    postal_code: "8001",
  },
  {
    surbub: "Fresnaye",
    postal_code: "7925",
  },
  {
    surbub: "Gardens",
    postal_code: "7925",
  },
  {
    surbub: "Gardens",
    postal_code: "7925",
  },
  {
    surbub: "Glencairn",
    postal_code: "7130",
  },
  {
    surbub: "Glencairn",
    postal_code: "7140",
  },
  {
    surbub: "Goodwood",
    postal_code: "7130",
  },
  {
    surbub: "Gordon's Bay",
    postal_code: "7130",
  },
  {
    surbub: "Grassy Park",
    postal_code: "7130",
  },
  {
    surbub: "Green Point",
    postal_code: "7140",
  },
  {
    surbub: "Green Point",
    postal_code: "7493",
  },
  {
    surbub: "Gugulethu",
    postal_code: "7530",
  },
  {
    surbub: "Hanover Park",
    postal_code: "7560",
  },
  {
    surbub: "Harfield Village",
    postal_code: "7441",
  },
  {
    surbub: "Heathfield",
    postal_code: "7405",
  },
  {
    surbub: "Higgovale",
    postal_code: "7100",
  },
  {
    surbub: "Higgovale",
    postal_code: "7550",
  },
  {
    surbub: "Hout Bay",
    postal_code: "7100",
  },
  {
    surbub: "Hout Bay",
    postal_code: "7441",
  },
  {
    surbub: "Imizamo Yethu",
    postal_code: "7490",
  },
  {
    surbub: "Kalk Bay",
    postal_code: "7460",
  },
  {
    surbub: "Kalk Bay",
    postal_code: "7405",
  },
  {
    surbub: "Kalksteenfontein",
    postal_code: "7570",
  },
  {
    surbub: "Kenilworth",
    postal_code: "7579",
  },
  {
    surbub: "Kensington",
    postal_code: "7530",
  },
  {
    surbub: "Kenwyn",
    postal_code: "7405",
  },
  {
    surbub: "Khayelitsha",
    postal_code: "7605",
  },
  {
    surbub: "Kirstenhof",
    postal_code: "7460",
  },
  {
    surbub: "Kommetjie",
    postal_code: "7500",
  },
  {
    surbub: "Kommetjie",
    postal_code: "7500",
  },
  {
    surbub: "Kraaifontein",
    postal_code: "7500",
  },
  {
    surbub: "Kreupelbosch",
    postal_code: "7405",
  },
  {
    surbub: "Kuils River",
    postal_code: "7460",
  },
  {
    surbub: "Lakeside",
    postal_code: "7975",
  },
  {
    surbub: "Lakeside",
    postal_code: "7975",
  },
  {
    surbub: "Langa",
    postal_code: "7975",
  },
  {
    surbub: "Lansdowne",
    postal_code: "7975",
  },
  {
    surbub: "Lavender Hill",
    postal_code: "7975",
  },
  {
    surbub: "Llandudno",
    postal_code: "7975",
  },
  {
    surbub: "Llandudno",
    postal_code: "7945",
  },
  {
    surbub: "Loevenstein",
    postal_code: "7945",
  },
  {
    surbub: "Lotus River",
    postal_code: "7975",
  },
  {
    surbub: "Lower Vrede (District Six)",
    postal_code: "7945",
  },
  {
    surbub: "Lower Vrede (District Six)",
    postal_code: "",
  },
  {
    surbub: "Macassar",
    postal_code: "7975",
  },
  {
    surbub: "Maitland",
    postal_code: "",
  },
  {
    surbub: "Mamre",
    postal_code: "7975",
  },
  {
    surbub: "Manenberg",
    postal_code: "7945",
  },
  {
    surbub: "Marina da Gama",
    postal_code: "7975",
  },
  {
    surbub: "Marina da Gama",
    postal_code: "7975",
  },
  {
    surbub: "Masiphumelele",
    postal_code: "7945",
  },
  {
    surbub: "Masiphumelele",
    postal_code: "7945",
  },
  {
    surbub: "Meadowridge",
    postal_code: "7708",
  },
  {
    surbub: "Melkbosstrand",
    postal_code: "7708",
  },
  {
    surbub: "Milnerton",
    postal_code: "7806",
  },
  {
    surbub: "Milnerton",
    postal_code: "7945",
  },
  {
    surbub: "Mitchells Plain",
    postal_code: "7941",
  },
  {
    surbub: "Montague Gardens",
    postal_code: "7708",
  },
  {
    surbub: "Monte Vista",
    postal_code: "7945",
  },
  {
    surbub: "Mouille Point",
    postal_code: "7708",
  },
  {
    surbub: "Mouille Point",
    postal_code: "7780",
  },
  {
    surbub: "Mowbray",
    postal_code: "7945",
  },
  {
    surbub: "Muizenberg",
    postal_code: "7806",
  },
  {
    surbub: "Muizenberg",
    postal_code: "7780",
  },
  {
    surbub: "Ndabeni",
    postal_code: "7941",
  },
  {
    surbub: "Newlands",
    postal_code: "7806",
  },
  {
    surbub: "Noordhoek (PO boxes only)",
    postal_code: "7700",
  },
  {
    surbub: "Noordhoek (PO boxes only)",
    postal_code: "7405",
  },
  {
    surbub: "Nyanga",
    postal_code: "7700",
  },
  {
    surbub: "Observatory",
    postal_code: "7925",
  },
  {
    surbub: "Ocean View",
    postal_code: "7405",
  },
  {
    surbub: "Ocean View",
    postal_code: "7800",
  },
  {
    surbub: "Oranjezicht",
    postal_code: "7945",
  },
  {
    surbub: "Oranjezicht",
    postal_code: "7700",
  },
  {
    surbub: "Ottery",
    postal_code: "7780",
  },
  {
    surbub: "Panorama",
    postal_code: "7700",
  },
  {
    surbub: "Parklands",
    postal_code: "7880",
  },
  {
    surbub: "Parow",
    postal_code: "7945",
  },
  {
    surbub: "Pelican Park",
    postal_code: "7945",
  },
  {
    surbub: "Philippi",
    postal_code: "7800",
  },
  {
    surbub: "Pinelands",
    postal_code: "7780",
  },
  {
    surbub: "Plattekloof",
    postal_code: "7975",
  },
  {
    surbub: "Plumstead",
    postal_code: "7975",
  },
  {
    surbub: "Retreat",
    postal_code: "7975",
  },
  {
    surbub: "Rondebosch",
    postal_code: "7975",
  },
  {
    surbub: "Rondebosch East",
    postal_code: "7975",
  },
  {
    surbub: "Rosebank",
    postal_code: "7975",
  },
  {
    surbub: "Rugby",
    postal_code: "7945",
  },
  {
    surbub: "Salt River",
    postal_code: "7945",
  },
  {
    surbub: "Salt River",
    postal_code: "7975",
  },
  {
    surbub: "Samora Machel",
    postal_code: "7945",
  },
  {
    surbub: "Scarborough (PO boxes only)",
    postal_code: "7975",
  },
  {
    surbub: "Schotsche Kloof",
    postal_code: "",
  },
  {
    surbub: "Schotsche Kloof",
    postal_code: "7975",
  },
  {
    surbub: "Sea Point",
    postal_code: "7945",
  },
  {
    surbub: "Sea Point",
    postal_code: "7975",
  },
  {
    surbub: "Simon's Town",
    postal_code: "7975",
  },
  {
    surbub: "Simon's Town",
    postal_code: "7945",
  },
  {
    surbub: "Sir Lowry's Pass Village",
    postal_code: "7806",
  },
  {
    surbub: "Somerset West",
    postal_code: "7349",
  },
  {
    surbub: "SouthField",
    postal_code: "7441",
  },
  {
    surbub: "St James",
    postal_code: "7345",
  },
  {
    surbub: "St James",
    postal_code: "7349",
  },
  {
    surbub: "Steenberg",
    postal_code: "7441",
  },
  {
    surbub: "Strand",
    postal_code: "7441",
  },
  {
    surbub: "Strandfontein",
    postal_code: "7441",
  },
  {
    surbub: "Sun Valley",
    postal_code: "7441",
  },
  {
    surbub: "Sunnydale",
    postal_code: "7441",
  },
  {
    surbub: "Sunnydale",
    postal_code: "8005",
  },
  {
    surbub: "Table View",
    postal_code: "8005",
  },
  {
    surbub: "Tamboerskloof",
    postal_code: "8005",
  },
  {
    surbub: "Thornton",
    postal_code: "8005",
  },
  {
    surbub: "Three Anchor Bay",
    postal_code: "7806",
  },
  {
    surbub: "Three Anchor Bay",
    postal_code: "8005",
  },
  {
    surbub: "Tokai",
    postal_code: "8005",
  },
  {
    surbub: "University Estate",
    postal_code: "8005",
  },
  {
    surbub: "Valhalla Park",
    postal_code: "8001",
  },
  {
    surbub: "Vredehoek",
    postal_code: "8001",
  },
  {
    surbub: "Vredehoek",
    postal_code: "8001",
  },
  {
    surbub: "Vrygrond",
    postal_code: "8001",
  },
  {
    surbub: "Walmer Estate (District Six)",
    postal_code: "8001",
  },
  {
    surbub: "Walmer Estate (District Six)",
    postal_code: "7925",
  },
  {
    surbub: "West Beach",
    postal_code: "8001",
  },
  {
    surbub: "Wetton",
    postal_code: "8001",
  },
  {
    surbub: "Woodstock (including Upper Woodstock)",
    postal_code: "8001",
  },
  {
    surbub: "Wynberg",
    postal_code: "7925",
  },
  {
    surbub: "Zonnebloem (District Six)",
    postal_code: "7925",
  },
];
