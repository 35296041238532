import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import Logo from "../../../img/logo.png";
import LogoLight from "../../../img/logo_light.png";
import LogoDark from "../../../img/logo_dark.png";

import "./common.css";

import toast from "react-hot-toast";

import jwt_decode from "jwt-decode";

import ModalUser from "./Modal";

class Header extends Component {
  state = {
    pathname: window.location.pathname,
    innerTabs: "",
    activeTab: "1",
    isLawyerAppModal: false,
    otpVerified: false,
    isQuote: false,
    toggleMenu: true,
    scroll: true,
    fullName: "",
    modalState: "",
    email: "",
    phone: {
      number: "",
      code: "",
      dialcountry: "",
    },
    password: "",
    new_password: "",
    confirmPassword: "",
    userData: "",
    otp1: "",
    otp2: "",
    otp3: "",
    otp4: "",
  };

  componentDidMount() {
    if (this.state.scroll) {
      window.addEventListener("scroll", this.changeColorOnScroll);
    }

    const getData = localStorage.getItem("KGU");

    if (getData) {
      const token = jwt_decode(getData);

      if (token) {
        this.setState({
          userData: token,
        });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.changeColorOnScroll);
  }

  logOut = () => {
    localStorage.removeItem("KGU");
    this.setState({
      userData: "",
    });

    toast.success("Logged out");

    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  userPage = (path) => {
    window.location.pathname = `/user/${path}`;
  };

  changeColorOnScroll = () => {
    const distanceY = window.pageYOffset || document.documentElement.scrollTop;
    const shrinkOn = 100;
    const headerEl = document.getElementById("js-colorchange");
    const navlink = headerEl.querySelectorAll(".nav-link");
    const logoEl = document.getElementById("logo-holder");
    const quote = document.querySelector(".quote").querySelector(".nav-link");
    const navbarToggler = document.querySelector(".navbar-toggler");
    const navbarTogglerIcon = document.querySelector(".navbar-toggle-icon");

    if (distanceY > shrinkOn) {
      headerEl.classList.add("bgNav-white");
      logoEl.setAttribute("src", LogoDark);
      navbarToggler.classList.remove("white-toggle");
      navbarTogglerIcon.classList.remove("white-toggle-icon");
      navlink.forEach((item) => {
        item.classList.add("bgNav-text");
        item.classList.remove("underline-whitebg");
        if (distanceY === 0) {
          item.classList.remove("underline-whitebg");
        }
        if (item.classList.contains("dropdown-toggle")) {
          item.classList.remove("underline-whitebg");
        } else {
          item.classList.add("underline-whitebg");
        }
      });
      quote.classList.add("buttonHoverNav");
      if (this.state.userData === "") {
        const signIn = document
          .querySelector(".signin")
          .querySelector(".nav-link");
        signIn.classList.add("buttonHoverNav");
      }
    } else {
      headerEl.classList.remove("bgNav-white");
      logoEl.setAttribute("src", LogoLight);
      navbarToggler.classList.add("white-toggle");
      navbarTogglerIcon.classList.add("white-toggle-icon");
      navlink.forEach((item) => {
        item.classList.remove("bgNav-text");
        item.classList.remove("underline-whitebg");
      });
      quote.classList.remove("buttonHoverNav");
      console.log(this.state.userData);
      if (this.state.userData === "") {
        const signIn = document
          .querySelector(".signin")
          .querySelector(".nav-link");
        signIn.classList.remove("buttonHoverNav");
      }
    }
  };

  setResponsiveNavSelectors = () => {
    const distanceY = window.pageYOffset || document.documentElement.scrollTop;
    const shrinkOn = 100;

    if (distanceY > shrinkOn) {
      const headerEl = document.getElementById("js-colorchange");
      headerEl.classList.add("bgNav-white");

      const logoEl = document.getElementById("logo-holder");
      const navbarToggler = document.querySelector(".navbar-toggler");
      const navbarTogglerIcon = document.querySelector(".navbar-toggle-icon");

      logoEl.setAttribute("src", LogoDark);
      navbarToggler.classList.remove("white-toggle");
      navbarTogglerIcon.classList.remove("white-toggle-icon");
    } else {
      const navbarToggler = document.querySelector(".navbar-toggler");
      navbarToggler.classList.add("white-toggle");
    }
  };
  onOpenLawyerAppModal = () => {
    this.setState({
      isLawyerAppModal: !this.state.isLawyerAppModal,
      email: "",
      modalState: "",
      otp1: "",
      otp2: "",
      otp3: "",
      otp4: "",
    });
  };
  onToggle = () => {
    const { toggleMenu } = this.state;
    this.setState(
      {
        toggleMenu: !toggleMenu,
        scroll: false,
      },
      () => {
        if (toggleMenu) {
          window.removeEventListener("scroll", this.changeColorOnScroll);
          const navbarToggler = document.querySelector(".navbar-toggler");
          navbarToggler.classList.remove("white-toggle");
        } else {
          window.addEventListener("scroll", this.changeColorOnScroll);

          this.setResponsiveNavSelectors();
        }
      }
    );
  };

  render() {
    const { pathname, isLawyerAppModal, userData, isQuote, toggleMenu } =
      this.state;
    return (
      <nav
        className={`navbar navbar-expand-lg header-page ${
          toggleMenu ? "" : "bgNav-white"
        } `}
        id="js-colorchange"
      >
        <div className="navbar-container">
          <div>
            <a href="/" className="navbar-brand logo-size logo-big">
              <img
                className="img-fluid"
                src={toggleMenu ? LogoLight : LogoDark}
                id="logo-holder"
              />
            </a>
          </div>
          <button
            type="button"
            className="navbar-toggler white-toggle"
            data-toggle="collapse"
            data-target="#navbarCollapse"
            onClick={() => this.onToggle()}
          >
            {/* white-toggle-icon */}
            <i
              className={`${
                toggleMenu ? "fa fa-bars white-toggle-icon" : "fa fa-times"
              } navbar-toggle-icon `}
            ></i>
          </button>
          <div
            id="navbarCollapse"
            className="collapse big-logo-position navbar-collapse"
          >
            <div className="navbar-nav">
              <div className="nav-item dropdown">
                <a
                  className="nav-link profile-dd dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Products
                </a>
                <div
                  className="dropdown-menu w-100 dropdown-menu w-100-right"
                  aria-labelledby="navbarDropdown"
                >
                  <Link to="/allproducts" className="dropdown-item profile">
                    Categories
                  </Link>
                  <div className="dropdown-divider"></div>
                  <Link to="/prodlist" className="dropdown-item profile">
                    All Products
                  </Link>
                </div>
              </div>
              <Link
                eventKey="services"
                to="/services"
                className={`nav-item nav-link services-link underline ${
                  pathname === "/services" ? "active" : ""
                } `}
              >
                Services
              </Link>
              <Link
                eventKey="services"
                to="/gallery"
                className={`nav-item nav-link services-link underline ${
                  pathname === "/gallery" ? "active" : ""
                } `}
              >
                Gallery
              </Link>
              <Link
                eventKey="aboutus"
                to="/aboutus"
                className={`nav-item nav-link aboutus-link underline ${
                  pathname === "/aboutus" ? "active" : ""
                } `}
              >
                About us
              </Link>
              <Link
                eventKey="contactus"
                to="/contactus"
                className={`nav-item nav-link contactus-link underline ${
                  pathname === "/contactus" ? "active" : ""
                } `}
              >
                Contact us
              </Link>
            </div>
            {/* after logged in start */}

            {userData !== "" ? (
              <>
                <div className="nav-item dropdown">
                  <a
                    className="nav-link profile-dd dropdown-toggle"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    My Account
                  </a>
                  <div
                    className="dropdown-menu w-100 dropdown-menu w-100-right"
                    aria-labelledby="navbarDropdown"
                  >
                    <Link
                      className="dropdown-item profile"
                      onClick={() => this.userPage("profile")}
                    >
                      Profile
                    </Link>
                    <div className="dropdown-divider"></div>
                    <Link
                      className="dropdown-item notification"
                      onClick={() => this.userPage("notifications")}
                    >
                      Notification
                    </Link>
                    <div className="dropdown-divider"></div>
                    <Link
                      className="dropdown-item myquote"
                      onClick={() => this.userPage("quotes")}
                    >
                      My Quote
                    </Link>
                    <div className="dropdown-divider"></div>
                    <Link
                      className="dropdown-item myorder"
                      onClick={() => this.userPage("orders")}
                    >
                      My Order
                    </Link>
                    <div className="dropdown-divider"></div>
                    <Link
                      className="dropdown-item logout"
                      onClick={this.logOut}
                    >
                      Logout
                    </Link>
                  </div>
                </div>
                <div className="navbar-nav login-btn">
                  <div className="quote">
                    <Link to="/quotes" className="nav-link" id="quoteLinkHome">
                      Request a Quote
                    </Link>
                  </div>
                </div>
              </>
            ) : (
              <div className="navbar-nav login-btn">
                <div className="quote">
                  <Link to="/quotes" className="nav-link" id="quoteLink">
                    Request a Quote
                  </Link>
                </div>
                <div className="signin">
                  <a
                    to="/signin"
                    className="nav-link"
                    id="signInLink"
                    onClick={this.onOpenLawyerAppModal}
                  >
                    Sign In
                  </a>
                </div>
              </div>
            )}
          </div>

          {isLawyerAppModal && (
            <ModalUser
              isLawyerAppModal={isLawyerAppModal}
              onOpenLawyerAppModal={this.onOpenLawyerAppModal}
              isQuote={isQuote}
              {...this.props}
            />
          )}
        </div>
      </nav>
    );
  }
}
export default withRouter(Header);
