import React, { useState } from "react";

import SwiperCore, { Navigation, Pagination, Thumbs } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { NodeURL } from "../../../api/api";

import StarryRed from "../../../img/Image15@3x.png";

SwiperCore.use([Navigation, Pagination, Thumbs]);

function SwiperSlider(props) {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [imageClick, setImageClick] = useState(false);
  const [id, setId] = useState("");
  const [number, setnumber] = useState(0);

  const {
    pictures,
    name,
    subCategoryName,
    description,
    price,
    pricePer,
    mainCategory,
    subCategory,
    product_id,
    colour,
  } = props;
  return (
    <div>
      <div className="container-fluid modalpopupproducts">
        <div className="row">
          <div className="col-md-6">
            <div className="img-box">
              <Swiper
                className="gallery-top"
                thumbs={{ swiper: thumbsSwiper }}
                slidesPerView={1}
                navigation={true}
                loop={true}
                pagination={true}
                autoplay={{ delay: 3000 }}
                spaceBetween={5}
                // onSlideChange={(e) => console.log(e)}
                // onSwiper={(swiper) => console.log(swiper)}
                breakpoints={{
                  // when window width is >= 640px
                  240: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                  },
                  // when window width is >= 768px
                  768: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                  },
                  1024: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                  },
                }}
              >
                {pictures.length > 0
                  ? pictures.map((item, i) => (
                      <SwiperSlide key={i}>
                        <img
                          alt="images"
                          className="img-fluid modal-img"
                          src={
                            item.path !== ""
                              ? NodeURL + "/" + item.path
                              : StarryRed
                          }
                        />
                      </SwiperSlide>
                    ))
                  : null}
              </Swiper>
            </div>
          </div>
          <div className="col-md-6">
            <div className="modal-content-box">
              <h5>{name}</h5>
              <p class="first-content">{subCategoryName}</p>
              <p>{description}</p>
              <p class="second-content">Starts at</p>
              <p class="third-content">
                R{price} {pricePer}
              </p>
            </div>
            <hr className="modal-underline" />
            <div className="modal-btn">
              <button
                className="btn btn-light mb-3"
                onClick={() =>
                  props.onPageQuote(mainCategory, subCategory, product_id, colour)
                }
              >
                Request a Quote{" "}
              </button>
            </div>
          </div>
        </div>
        <div className="img-preview">
          <div id="img-preview-container">
            {pictures.length > 0 ? (
              <Swiper
                // style={{
                //   "--swiper-navigation-color": "#a2a3a2",
                //   "--swiper-pagination-color": "#a2a3a2",
                // }}
                className="third-gallery"
                onSwiper={setThumbsSwiper}
                slidesPerView={5}
                freeMode={true}
                watchSlidesVisibility={true}
                watchSlidesProgress={true}
                // onSlideChange={(swiper) =>{
                //   this.setState({slideSwiper: swiper})
                //   console.log("swiper", swiper);
                // }}
                breakpoints={{
                  // when window width is >= 640px
                  240: {
                    slidesPerView: 5,
                    spaceBetween: 10,
                  },
                  // when window width is >= 768px
                  768: {
                    slidesPerView: 5,
                    spaceBetween: 10,
                  },
                  1024: {
                    slidesPerView: 5,
                    spaceBetween: 20,
                  },
                }}
              >
                {pictures.map((item, i) => (
                  <SwiperSlide key={i}>
                    <div
                      className="box-section"
                      onClick={(swiper) => {
                        setImageClick(true);
                        setId(item._id);
                        setnumber(false);
                      }}
                    >
                      <img
                        alt=""
                        className={`img-fluid modal-small-img ${
                          imageClick && id === item._id
                            ? "outline-1"
                            : i === number
                            ? "outline-1"
                            : ""
                        }`}
                        id="imgFluid"
                        src={
                          item.path !== ""
                            ? NodeURL + "/" + item.path
                            : StarryRed
                        }
                      />
                      {/* <div className="content d-inline-block ml-3 text-left">
                              <h5>{item.name}</h5>
                              <p>{item.subCategoryName}</p>
                            </div> */}
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SwiperSlider;
