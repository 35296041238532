import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Home from './Components/Pages/Home/Home';
import AllProducts from './Components/Pages/AllProducts/AllProducts';
import ContactUs from './Components/Pages/ContactUs/ContactUs';
import AllProductsList from './Components/Pages/AllProductsList/AllProductsList';
import AboutUs from './Components/Pages/AboutUs/AboutUs';
import reportWebVitals from './reportWebVitals';

import Quotes from './Components/Pages/Quotes/Quotes';
import QuoteSummary from './Components/Pages/Quotes/QuoteSummary';

import Services from './Components/Pages/Services/Services';
import Gallery from './Components/Pages/Gallery/Gallery';
import GalleryDetails from './Components/Pages/Gallery/GalleryDetails';
import TermsAndConditions from './Components/Pages/Termsandconditions/TermsandConditions';
import PrivacyPolicy from './Components/Pages/Privacypolicy/PrivacyPolicy';

import request from './api/api';

import 'bootstrap/dist/js/bootstrap.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import 'react-loading-skeleton/dist/skeleton.css';

import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Footer from './Components/Pages/Common/Footer';

const updateDomTitle = async () => {
  try {
    const res = await request({ url: '/getGeneralSettings', method: 'POST' }).catch((error) => {});

    if (res.status === 1) {
      const { settings } = res;
      document.title = settings?.site_title ?? 'Kingdom Granites"';
    }
  } catch (error) {}
};

updateDomTitle();

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Switch>
        <Route exact path='/' name='Home' component={Home} />
        <Route exact path='/allproducts' name='AllProducts' component={AllProducts} />
        <Route exact path='/prodlist' name='AllProductsList' component={AllProductsList} />
        <Route exact path='/contactus' name='ContactUs' component={ContactUs} />
        <Route exact path='/aboutus' name='AboutUs' component={AboutUs} />

        <Route exact path='/quotes' name='Quotes' component={Quotes} />
        <Route exact path='/quote/summary' name='Quotes' component={QuoteSummary} />

        <Route exact path='/services' name='Services' component={Services} />
        <Route exact path='/gallery' name='Gallery' component={Gallery} />
        <Route exact path='/gallery/details' name='GalleryDetails' component={GalleryDetails} />
        <Route exact path='/privacypolicy' name='PrivacyPolicy' component={PrivacyPolicy} />
        <Route
          exact
          path='/termsandconditions'
          name='termsandconditions'
          component={TermsAndConditions}
        />
      </Switch>
      <Footer />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
