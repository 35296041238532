import jwtDecode from "jwt-decode";
import React, { Component } from "react";
import toast, { Toaster } from "react-hot-toast";

import { Link } from "react-router-dom";
import request from "../../../api/api";
import HeaderOthers from "../Common/HeaderOthers";

import ModalUser from "../Common/Modal";

export default class QuoteSummary extends Component {
  state = {
    data:
      localStorage.getItem("KGQS") !== null
        ? localStorage.getItem("KGQS")
        : null,
    userData: "",
    isLawyerAppModal: false,
    isQuote: false,
    quoteData: {
      service_name: "",
      isLawyerAppModal: false,
      isQuote: false,
      userData: "",
      type: "guest",
      mainCategory: "",
      mainCategoryName: "",
      name: "",
      price: "",
      pricePer: "",
      status: "",
      subCategory: "",
      subCategoryName: "",

      mainCategorySelect: "",
      mainCategorySelectValue: "",

      subCategorySelect: "",
      subCategorySelectValue: "",

      productList: "",
      productListValue: "",

      primary_colourID: "",

      coloursSelect: "",
      coloursSelectValue: "",

      secondaryColoursSelect: "",
      secondaryColoursSelectValue: "",

      meters: 0,
      running_meters: false,
      square_meters: false,
      type_sink: {
        drop_in: false,
        undermounted: false,
        butter: false,
        on_top: false,
        other: false,
        none: false,
      },
      extras: {
        splashback: false,
        kickplates: false,
        hob_clabbing: false,
      },
      contact: {
        line1: "",
        line2: "",
        province: "",
        city: "",
        postal_code: "",
      },
      remove_countertops: "",
      install_groundfloor: "",
      isDesignPlan: "",
      notes: "",
      message: "",

      pictures: [],
      any_details: "",

      project_urgencyValue: "",
    },
  };
  componentDidMount() {
    window.scroll(0, 0);
    const { data } = this.state;

    if (data === null) return this.props.history.push("/quotes");

    this.setState({
      quoteData: JSON.parse(data),
    });
    this.checkUser();
  }

  componentWillUnmount() {
    if (this.props.history.location.pathname !== "/quotes") {
      localStorage.removeItem("KGQS");
    }
  }

  checkUser = () => {
    const getData = localStorage.getItem("KGU");

    if (getData) {
      const token = jwtDecode(getData);

      if (token) {
        this.setState({
          userData: token,
        });
      }
    }

    if (getData !== null) {
      this.setState({
        type: "user",
      });
    }
  };

  onSubmitQuotesInformation = () => {
    this.checkQuoteData();
  };

  checkQuoteData = () => {
    const { contact, productList, service_name } = this.state.quoteData;

    if (service_name === "" || service_name === undefined) {
      if (productList === "")
        return toast.error("Stone name required.", {
          id: "submit",
        });
    }

    if (contact.line1 === "")
      return toast.error("Address required.", {
        id: "submit",
      });
    if (contact.line2 === "")
      return toast.error("Suburb required.", {
        id: "submit",
      });
    if (contact.province === "")
      return toast.error("Province required.", {
        id: "submit",
      });
    if (contact.city === "")
      return toast.error("City required.", {
        id: "submit",
      });
    if (contact.postal_code === "")
      return toast.error("Postal Code required.", {
        id: "submit",
      });
    this.onCheckUser();
  };

  onCheckUser = () => {
    const { userData } = this.state;

    // if no user
    if (userData === "") {
      return this.setState({
        isLawyerAppModal: true,
        isQuote: true,
      });
    }

    this.quoteSubmitAPI();
  };

  quoteSubmitAPI = (modal, user, typeUser) => {
    const {
      primary_colourID,
      productList,
      pictures,
      any_details,
      type,
      meters,
      running_meters,
      square_meters,
      type_sink,
      extras,
      remove_countertops,
      install_groundfloor,
      isDesignPlan,
      notes,
      project_urgency,
      contact,
      message,
      service_name,
      mainCategorySelect,
      subCategorySelect,
      coloursSelect,
      secondaryColoursSelect,
    } = this.state.quoteData;

    const { userData } = this.state;

    const data = {
      userData: userData._id,
      type,
      meters,
      running_meters,
      square_meters,
      type_sink,
      extras,
      remove_countertops,
      install_groundfloor,
      isDesignPlan,
      notes,
      project_urgency,
      contact,
      message,
      primary_colourID,
      productList,
      pictures: JSON.stringify(pictures),
      any_details,
      service_name,
    };
    if (modal === "user") {
      data.userData = user._id;
      data.type = typeUser;
    }
    if (service_name === "" || service_name === undefined) {
      data.primary_colour = coloursSelect;
      data.secondary_colour = secondaryColoursSelect;
      data.mainCategory = mainCategorySelect;
      data.subCategory = subCategorySelect;
    }

    request({
      url: "/site/save/quotes",
      method: "POST",
      data,
    }).then((res) => {
      if (res && +res.status === 1) {
        toast.success("Quote Submitted !", {
          id: "submit",
        });

        window.location.pathname = "/user/quotes";
        localStorage.removeItem("KGQS");

        if (userData === "") {
          this.setState({
            isLawyerAppModal: true,
          });
        }

        this.setState(
          {
            isLawyerAppModal: false,
            mainCategory: "",
            mainCategoryName: "",

            mainCategoryListselect: [],
            mainCategorySelect: "",
            mainCategorySelectValue: "",

            subCategoryListSelect: [],
            subCategorySelect: "",
            subCategorySelectValue: "",

            primary_colourID: "",
            secondary_colourID: "",

            coloursSelect: "",
            coloursSelectValue: "",

            secondaryColoursSelect: "",
            secondaryColoursSelectValue: "",

            meters: "",
            running_meters: false,
            square_meters: false,
            type_sink: {
              drop_in: false,
              undermounted: false,
              butter: false,
              on_top: false,
              other: false,
              none: false,
            },
            extras: {
              splashback: false,
              kickplates: false,
              hob_clabbing: false,
            },
            contact: {
              first_name: "",
              last_name: "",
              mobile: {
                code: "",
                number: "",
                dialcountry: "",
              },
              email: "",
              city: "",
              preferred_contact: "",
            },
            remove_countertops: "",
            install_groundfloor: "",
            isDesignPlan: "",
            notes: "",
            message: "",

            project_urgency: "",
            project_urgencyValue: "",
          },
          () => {
            if (modal === "user") {
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            }
          }
        );
        this.componentDidMount();
      } else if (res && +res.status === 0) {
        toast.error("Update error !.");
      }
    });
  };

  onOpenLawyerAppModal = () => {
    this.setState({
      isLawyerAppModal: !this.state.isLawyerAppModal,
      email: "",
      modalState: "",
      otp1: "",
      otp2: "",
      otp3: "",
      otp4: "",
    });
  };

  onEdit = () => {
    return this.props.history.push("/quotes");
  };
  render() {
    const {
      mainCategorySelectValue,
      subCategorySelectValue,
      coloursSelectValue,
      productListValue,
      secondaryColoursSelectValue,
      meters,
      type_sink,
      extras,
      remove_countertops,
      install_groundfloor,
      isDesignPlan,
      project_urgency,
      contact,
      notes,
      any_details,
      running_meters,
      square_meters,
    } = this.state.quoteData;

    const { isLawyerAppModal, isQuote } = this.state;
    return (
      <>
        <HeaderOthers />
        <div>
          <div className="quote-summary-wrap container">
            <Toaster
              position="bottom-center"
              reverseOrder={false}
              containerClassName=""
              containerStyle={{}}
              toastOptions={{
                // Define default options

                // Default options for specific types
                duration: 3000,
                style: {
                  background: "#363636",
                  color: "#fff",
                  width: "100%",
                },
              }}
            />
            <div className="row align-items-center">
              <div className="col-md-3">
                <div className="homepage">
                  <Link to={{ pathname: "/quotes" }}>Quote Request</Link>
                  <Link to="/quote/summary">
                    &nbsp;<i className="fa fa-circle" aria-hidden="true"></i>{" "}
                    Summary
                  </Link>
                </div>
              </div>
              <div className="text-center col-md-6">
                <div className="content-box">
                  <h2>Quote Summary</h2>
                </div>
              </div>
            </div>
            <div className="quote-action-buttons">
              <button
                className="quotessummarypage btn btn-light"
                onClick={() => this.onEdit()}
              >
                Edit &nbsp;&nbsp;
                <i className="fa fa-edit" aria-hidden="true"></i>
              </button>
              {/* <button
              className="quotessummarypage btn btn-light"
              onClick={this.onSubmitQuotesInformation}
            >
              Submit Request
            </button> */}
            </div>
            <div className="container quote-summary">
              <table className="table table-borderless quote-table">
                <tbody>
                  <tr>
                    <td style={{ width: "20%" }} className="quote-table-head">
                      Material
                    </td>
                    <td style={{ width: "10%" }}>:</td>
                    <td style={{ width: "70%" }}>
                      {mainCategorySelectValue !== ""
                        ? mainCategorySelectValue[0].label
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "20%" }} className="quote-table-head">
                      Brand
                    </td>
                    <td style={{ width: "10%" }}>:</td>
                    <td style={{ width: "70%" }}>
                      {subCategorySelectValue !== ""
                        ? subCategorySelectValue[0].label
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "20%" }} className="quote-table-head">
                      Stone Name
                    </td>
                    <td style={{ width: "10%" }}>:</td>
                    <td style={{ width: "70%" }}>
                      {productListValue !== "" ? productListValue[0].label : ""}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "20%" }} className="quote-table-head">
                      Primary Color
                    </td>
                    <td style={{ width: "10%" }}>:</td>
                    <td style={{ width: "70%" }}>
                      {coloursSelectValue !== ""
                        ? coloursSelectValue[0].label
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "20%" }} className="quote-table-head">
                      Secondary Color
                    </td>
                    <td style={{ width: "10%" }}>:</td>
                    <td style={{ width: "70%" }}>
                      {secondaryColoursSelectValue !== ""
                        ? secondaryColoursSelectValue[0].label
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "20%" }} className="quote-table-head">
                      No.of Meters
                    </td>
                    <td style={{ width: "10%" }}>:</td>
                    <td style={{ width: "70%" }}>
                      {meters} {running_meters ? "Running Meters," : ""}{" "}
                      {square_meters ? "Square Meters" : ""}{" "}
                    </td>
                  </tr>

                  <tr>
                    <td style={{ width: "20%" }} className="quote-table-head">
                      Sink Type
                    </td>
                    <td style={{ width: "10%" }}>:</td>
                    <td style={{ width: "70%" }}>
                      {" "}
                      {type_sink.drop_in ? "Drop In," : ""}{" "}
                      {type_sink.undermounted ? "Undermounted," : ""}{" "}
                      {type_sink.butter ? "Butter," : ""}{" "}
                      {type_sink.on_top ? "On top," : ""}{" "}
                      {type_sink.other ? "Other," : ""}{" "}
                      {type_sink.none ? "None" : ""}{" "}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "20%" }} className="quote-table-head">
                      Extras
                    </td>
                    <td style={{ width: "10%" }}>:</td>
                    <td style={{ width: "70%" }}>
                      {extras.splashback ? "Splashback," : ""}{" "}
                      {extras.kickplates ? "Kickplates," : ""}{" "}
                      {extras.hob_clabbing ? "Hob Clabbing" : ""}{" "}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "20%" }} className="quote-table-head">
                      Remove Your Existing Countertops
                    </td>
                    <td style={{ width: "10%" }}>:</td>
                    <td style={{ width: "70%" }}>{remove_countertops}</td>
                  </tr>
                  <tr>
                    <td style={{ width: "20%" }} className="quote-table-head">
                      Address
                    </td>
                    <td style={{ width: "10%" }}>:</td>
                    <td style={{ width: "70%" }}>
                      {contact.line1 ? contact.line1 + "," : ""}{" "}
                      {contact.line2 ? contact.line2 + "," : ""}{" "}
                      {contact.province ? contact.province + "," : ""}{" "}
                      {contact.city ? contact.city + "," : ""}{" "}
                      {contact.postal_code ? contact.postal_code : ""}{" "}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "20%" }} className="quote-table-head">
                      Is The Place Of Installation On The Ground Floor With
                      Nearby Parking And Good Access?
                    </td>
                    <td style={{ width: "10%" }}>:</td>
                    <td style={{ width: "70%" }}>{install_groundfloor}</td>
                  </tr>
                  <tr>
                    <td style={{ width: "20%" }} className="quote-table-head">
                      Notes
                    </td>
                    <td style={{ width: "10%" }}>:</td>
                    <td style={{ width: "70%" }}>{notes}</td>
                  </tr>
                  <tr>
                    <td style={{ width: "20%" }} className="quote-table-head">
                      Project Urgency
                    </td>
                    <td style={{ width: "10%" }}>:</td>
                    <td style={{ width: "70%" }}>{project_urgency}</td>
                  </tr>
                  <tr>
                    <td style={{ width: "20%" }} className="quote-table-head">
                      Do You Have A Design Plan / Diagram For Your Project?
                    </td>
                    <td style={{ width: "10%" }}>:</td>
                    <td style={{ width: "70%" }}>{isDesignPlan}</td>
                  </tr>
                  <tr>
                    <td style={{ width: "20%" }} className="quote-table-head">
                      Any Other Details?
                    </td>
                    <td style={{ width: "10%" }}>:</td>
                    <td style={{ width: "70%" }}>{any_details}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <section className="pt-2 mb-5">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12 text-center">
                  <div>
                    <button
                      className="quotessummarypage btn btn-light"
                      onClick={this.onSubmitQuotesInformation}
                    >
                      Submit Request
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {isLawyerAppModal && (
            <ModalUser
              isLawyerAppModal={isLawyerAppModal}
              onOpenLawyerAppModal={this.onOpenLawyerAppModal}
              quoteSubmitAPI={this.quoteSubmitAPI}
              isQuote={isQuote}
              {...this.props}
            />
          )}
        </div>
      </>
    );
  }
}
