import React, { useState } from "react";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
  UncontrolledCarousel,
} from "reactstrap";

// Slider Image
import slide1 from "../../../img/slide1.png";
import slide2 from "../../../img/slide2.png";
import slide3 from "../../../img/slide3.png";

function Slider() {
  const items = [
    {
      src: slide1,
      altText: "",
      caption: (
        <div className="indicators-custom">
          <div className="caption-line"></div>
          <span className="mr-3">2</span>
          <div>3</div>
        </div>
      ),
      key: "1",
    },
    {
      src: slide2,
      altText: "Slide 2",
      caption: (
        <div className="indicators-custom">
          <span>1</span>
          <div className="caption-line"></div>
          <div>3</div>
        </div>
      ),
      key: "2",
    },
    {
      src: slide3,
      altText: "Slide 3",
      caption: (
        <div className="indicators-custom">
          <span>1</span>
          <div className="ml-3">2</div>
          <div className="caption-line"></div>
        </div>
      ),
      key: "3",
    },
  ];

  return (
    <UncontrolledCarousel
      items={items}
      controls={false}
      indicators={false}
      interval={5000}
      className="carousel-fade"
    />
  );
}

export default Slider;
