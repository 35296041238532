import React, { Component } from "react";
import { Link } from "react-router-dom";

import request from "../../../api/api";
import TermsImg from "../../../img/Security-pana@3x.png";

import HeaderOthers from "../Common/HeaderOthers";

import "../Termsandconditions/termsandconditions.css";

class PrivacyPolicy extends Component {
  state = {
    content: "",
  };
  componentDidMount() {
    window.scroll(0, 0);
    this.getPagesAPI();
  }
  getPagesAPI = () => {
    const pathname = this.props.location.pathname.replace(/[^\w\s]/gi, "");
    request({
      url: "/site/pages/get",
      method: "POST",
      data: {
        slug: pathname,
      },
    }).then((res) => {
      if (res && +res.status === 1) {
        const { content } = res.response.result;

        this.setState({
          isLoader: false,
          content,
        });
      } else if (res && +res.status === 0) {
        this.setState({
          isLoader: false,
        });
      }
    });
  };
  render() {
    let texts = this.state.content;
    function createMarkup(text) {
      return { __html: text };
    }
    return (
      <>
        <HeaderOthers />
        <div className="termspage">
          <section className="pp-hero-section">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-3">
                  <div className="homepage">
                    <Link to="/">Home</Link>
                    <Link to="/privacypolicy">
                      <i className="fa fa-circle" aria-hidden="true"></i>
                      Privacy Policy
                    </Link>
                  </div>
                </div>
                <div className="text-center col-md-6">
                  <div className="content-box">
                    <h2>Privacy Policy</h2>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <div className="content-box">
                    <p dangerouslySetInnerHTML={createMarkup(texts)} />
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="img-box">
                    <img className="img-fluid" src={TermsImg} />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

export default PrivacyPolicy;
